import { Fragment } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import linha_idiomas from "../static/img/linha_idiomas.png";
import logo from "../static/img/logo.png";
import logoUfrn from "../static/img/logoUfrn.gif";
import TopMenu from "./TopMenu";

function change_site_language(language, dispatch, i18n) {
  dispatch({ language });
  i18n.changeLanguage(language);
  document.title =
    language === "pt" ? "Instituto do Cérebro" : "Brain Institute";
}

function Top() {
  const [state, dispatch] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <div className="top" />
      <header>
        <Link to="/" className="logo">
          <img src={logo} />
        </Link>
        <div id="header_busca_idioma">
          <ul className="idiomas">
            <li>
              <a onClick={() => change_site_language("pt", dispatch, i18n)}>
                <h5>Português - BR</h5>
                <i className="fa fa-chevron-down"></i>
              </a>
              <ol>
                <li>
                  <img src={linha_idiomas} />
                  <a onClick={() => change_site_language("en", dispatch, i18n)}>
                    {/* <span className="opcoes_idomas_texto">Inglês - EUA</span> */}
                    <h5>Inglês - EUA</h5>
                  </a>
                </li>
              </ol>
            </li>
          </ul>
          <img className="logo_ufrn_comemorativa" src={logoUfrn} />
        </div>
      </header>
      <TopMenu />
    </Fragment>
  );
}

export default Top;
