import { API_SERVER } from "../constants";
// import { membros_names_mapping } from "../constants";
import { Link } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../context";

function SupervisedMemberLink({ name, supervised_member }) {
  const member_link = `/instituto/membros/${name}/${supervised_member.academic.short_name}`;
  return (
    <Fragment>
      <Link to={member_link}>{supervised_member.academic.name}</Link>
      <br />
    </Fragment>
  );
}

function SupervisedMembersList({ name, members_list, is_pt }) {
  const endpoint_membros_tipo = `${API_SERVER}/api/v1/instituto/membros/${name}/info`;
  const [membroTipoInfo, setMembroTipoInfo] = useState([]);
  const [errorMembroTipoInfo, setErrorMembroTipoInfo] = useState([]);

  useEffect(() => {
    fetch_members_type_info();
  }, [name]);

  const fetch_members_type_info = () => {
    fetch(endpoint_membros_tipo)
      .then((res) => res.json())
      .then(
        (data) => {
          setMembroTipoInfo(data);
        },
        (error) => {
          setErrorMembroTipoInfo(error);
        }
      );
  };

  return (
    <li>
      <b>{is_pt ? membroTipoInfo.label_pt : membroTipoInfo.label_en}</b>
      <br />
      {members_list.map((member, index) => (
        <SupervisedMemberLink
          key={index}
          name={name}
          supervised_member={member}
          index={index}
        />
      ))}
    </li>
  );
}

function MembrosDetalhesBarraOrientador({ pi_name }) {
  const endpoint = `${API_SERVER}/api/v1/instituto/membros/chefes_de_laboratorio/${pi_name}/supervised`;

  const [supervised, setSupervised] = useState([]);
  const [errorSupervised, setErrorSupervised] = useState(null);
  const [state, _] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const is_pt = state.language == "pt";

  useEffect(() => {
    fetch(endpoint)
      .then((res) => res.json())
      .then(
        (data) => {
          setSupervised(data);
        },
        (error) => {
          setErrorSupervised(error);
        }
      );
  }, []);

  const supervised_order = is_pt
    ? [
        "anteriores",
        "colaboradores",
        "ensino_medio",
        "graduandos",
        "mestrandos",
        "doutorandos",
        "pos_docs",
        "gerente_de_laboratorio",
      ]
    : [
        "previous",
        "collaborators",
        "high_school",
        "undergrads",
        "msc_students",
        "phd_students",
        "post_docs",
        "lab_manager",
      ];

  const get_supervised_members = (members_class) =>
    supervised.filter(
      (student) =>
        (is_pt ? student.bond.short_name_pt : student.bond.short_name_en) ===
        members_class
    );

  const supervised_members_bar = supervised_order.map(
    (members_class, index) => (
      <SupervisedMembersList
        name={members_class}
        key={index}
        members_list={get_supervised_members(members_class)}
        is_pt={is_pt}
      />
    )
  );

  return (
    <aside className="menu_membros chefe_lab">
      <h3>{t("Orientador")}</h3>
      <ul>{supervised_members_bar}</ul>
    </aside>
  );
}

export default MembrosDetalhesBarraOrientador;
