import { API_SERVER } from "../constants";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { LanguageContext } from "../context";
import MembrosMenuLink from "./TopMenuMembrosLink";

function MembrosMenu() {
  const [bondInfo, setBondInfo] = useState([]);
  const [errorBondInfo, setErrorBondInfo] = useState([]);
  const [state, dispatch] = useContext(LanguageContext);

  const endpoint = `${API_SERVER}/api/v1/instituto/membros`;

  const fetch_bond_info = () => {
    fetch(endpoint)
      .then((res) => res.json())
      .then(
        (data) => {
          setBondInfo(data);
        },
        (error) => {
          setErrorBondInfo(error);
        }
      );
  };

  useEffect(() => {
    fetch_bond_info();
  }, []);

  const members_links = bondInfo
    // .filter((bond) => bond.label_en != "Staff")
    .sort((bondA, bondB) => bondA.order - bondB.order)
    .map((bond, index) => (
      <MembrosMenuLink
        key={index}
        nome={state.language == "pt" ? bond.label_pt : bond.label_en}
        member_link={
          state.language == "pt" ? bond.short_name_pt : bond.short_name_en
        }
      />
    ));

  return (
    <aside className="menu_membros">
      <ul>
        {members_links}
        {/* <MembrosMenuLink
          nome="Chefes de laboratório"
          member_link="chefes_de_laboratorio"
        />
        <MembrosMenuLink nome="Pós-doutorandos" member_link="pos_docs" />

        <MembrosMenuLink nome="Doutorandos" member_link="doutorandos" />

        <MembrosMenuLink nome="Mestrandos" member_link="mestrandos" />

        <MembrosMenuLink nome="Residentes" member_link="residentes" />

        <MembrosMenuLink
          nome="Estudantes de graduação"
          member_link="graduandos"
        />

        <MembrosMenuLink nome="Colaboradores" member_link="colaboradores" />

        <MembrosMenuLink nome="Corpo Técnico" member_link="tecnicos" />

        <MembrosMenuLink nome="Ensino Médio" member_link="ensino_medio" />

        <MembrosMenuLink
          nome="Gerente de Laboratório"
          member_link="gerente_de_laboratorio"
        />

        <MembrosMenuLink nome="Membros anteriores" member_link="anteriores" />

        <MembrosMenuLink nome="Diretoria" member_link="diretoria" /> */}
      </ul>
    </aside>
  );
}

export default MembrosMenu;
