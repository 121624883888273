import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import SemanaDoCerebro from "../static/img/7SemanadoCerebro_MST_1.jpg";

function ExtensaoProjetos() {
  const img_style = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const p_style = {
    display: "block",
    marginTop: "10px",
  };

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title="Projetos" />
        <div className="conteudo_internas">
          <br />
          <a href="http://neuro.ufrn.br/semanadocerebro/">
            <b>
              <h2>Semana do Cérebro</h2>
            </b>
          </a>
          <h2>
            <a href="http://neuro.ufrn.br/semanadocerebro/">
              <b></b>
            </a>
            <br />
            <br />
            {/* <img className="quem_somos_img" src={ICe1} /> */}
            <img style={img_style} src={SemanaDoCerebro} width="100%" />

            <p style={p_style}></p>
            <p>
              A unidade realiza ainda diversas ações de extensão de cunho
              científico, cultural e esportivo com crianças, adolescentes e
              adultos oriundos de escolas públicas, hospitais públicos e
              comunidades carentes. Os projetos são realizados em parceria com
              outros departamentos da UFRN, além de instituições e entidades
              públicas e privadas do Estado. Entre os anos de 2011 e 2017, estas
              ações alcançaram um público estimado em 18 mil pessoas.
            </p>
          </h2>
        </div>
      </div>
    </content>
  );
}

export default ExtensaoProjetos;
