import IndexEventos from "./IndexEventos";
import IndexSlideshow from "./IndexSlideshow";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_SERVER } from "../constants";
import { handleThumbnail } from "../core";
import { formatDate } from "../core";
import IndexICeNaMidia from "./IndexICeNaMidia";

function Index() {
  const { t, i18n } = useTranslation();
  const is_pt = i18n.language === "pt";

  const left_arrow = (
    <a>
      <i className="fa fa-chevron-left"></i>
    </a>
  );

  const right_arrow = (
    <a>
      <i className="fa fa-chevron-right"></i>
    </a>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: right_arrow,
    prevArrow: left_arrow,
  };

  const [publicacoes, setPublicacoes] = useState([]);
  const [errorPublicacoes, setErrorPublicacoes] = useState(null);

  const [slides, setSlides] = useState([]);
  const [errorSlides, setErrorSlides] = useState([]);

  const [publicacaoDestaque, setPublicacaoDestaque] = useState({});
  const [errorPublicacaoDestaque, setErrorPublicacaoDestaque] = useState(null);

  const [secaoDestaque, setSecaoDestaque] = useState(null);
  const [errorSecaoDestaque, setErrorSecaoDestaque] = useState(null);

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/pesquisa/publicacoes`)
      .then((res) => res.json())
      .then(
        (data) => {
          setPublicacoes(data);
        },
        (error) => {
          setErrorPublicacoes(error);
        }
      );
  }, []);

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/destaque`)
      .then((res) => res.json())
      .then(
        (data) => {
          setSecaoDestaque(data);
        },
        (error) => {
          setErrorSecaoDestaque(error);
        }
      );
  }, []);

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/slides`)
      .then((res) => res.json())
      .then(
        (data) => {
          setSlides(data);
          setPublicacaoDestaque(data[0]);
        },
        (error) => {
          setErrorSlides(error);
        }
      );
  }, []);

  let publications_message = <h3>{t("Mensagem de erro")}</h3>;
  let conteudo;

  let defaultSecaoDestaque = {
    title_en: "Highlight Section",
    title_pt: "Seção Destaque",
    text_pt: "Seção Destaque",
    text_en: "Highlight Section",
    url_en: "#",
    url_pt: "#",
  };

  const destaqueDisplay = secaoDestaque ? secaoDestaque : defaultSecaoDestaque;

  if (!errorPublicacoes) {
    conteudo = publicacoes.map((publicacao, index) => (
      <li key={index}>
        <strong className="data_index_noticias">
          {t("Publicado em:")} {formatDate(
            publicacao.date_publication.replaceAll("-", "/"),
            i18n.language === "pt"
          )}
        </strong>
        <a target="_blank" rel="noopener noreferrer" href={publicacao.url}>
          <img
            src={handleThumbnail(publicacao.thumbnail)}
            alt={publicacao.title_en}
          />
          {is_pt? publicacao.title_pt : publicacao.title_en}
        </a>
      </li>
    ));

    publications_message = conteudo;
  }

  const [parceiros, setParceiros] = useState([]);
  const [errorParceiros, setErrorParceiros] = useState({});

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/parceiros`)
      .then((res) => res.json())
      .then(
        (data) => {
          setParceiros(data);
        },
        (error) => {
          setErrorParceiros(error);
        }
      );
  }, []);

  for (let index = 0; index < parceiros.length; index++) {
    parceiros[index].image = parceiros[index].image.replace(
      "/media",
      `${API_SERVER}/static/core/_imgs/parceiros`
    );
  }

  const publicacoes_slides = publicacoes.filter(
    (publicacao) => publicacao.is_slide
  );

  return (
    <content>
      <div id="centralizar">
        <IndexSlideshow
          paper_big_picture={publicacaoDestaque}
          papers={slides}
        />

        <div id="index_noticia">
          <div className="titulo_index_noticias">
            <h3>{t("Publicações")}</h3>
          </div>
          <ul>{publications_message}</ul>
        </div>

        <div
          className="midia"
          style={{
            marginTop: "0px",
            marginBottom: "30px",
            padding: "0px 20px 0px 15px",
            borderRadius: "10px 0px 0px 10px",
            background: "#cccc5e",
            height: "40px",
          }}
        >
          <div
            className=""
            style={{
              display: "block",
              height: "42px",
              width: "470px",
              padding: "0px",
              margin: "0px",
              verticalAlign: "top",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFF",
                width: "5px",
                height: "5px",
                float: "left",
                marginTop: "10px",
                marginLeft: "-10px",
                marginRight: "10px",
                padding: "8px 8px 8px 8px",
                borderRadius: "20px",
              }}
            ></div>
            <h3
              style={{
                color: "#fff",
                fontSize: "22px",
                padding: "8px 8px 8px 0px",
                textTransform: "uppercase",
              }}
            >
              {/* {t("Painel COVID-19")} */}
              {is_pt ? destaqueDisplay.title_pt : destaqueDisplay.title_en}
            </h3>
          </div>
        </div>

        <div id="painel_covid19">
          <ul>
            <li>
              {is_pt ? destaqueDisplay.text_pt : destaqueDisplay.text_en}
            </li>
          </ul>
          <div className="mais">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={is_pt ? destaqueDisplay.url_pt : destaqueDisplay.url_en}
            >
              {t("SAIBA")} <i className="fa fa-plus-circle"></i>
            </a>
          </div>
        </div>

        <IndexICeNaMidia />
        <IndexEventos />
        {/* ICe na Midia */}
        {/* <div className="banners"> */}
        <Slider {...settings} className="banners">
          {parceiros.map((partner, index) => {
            return (
              <a
                key={index}
                href={partner.url ? partner.url : "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={partner.image}
                  alt={partner.label ? partner.label : "ICe Partner"}
                />
              </a>
            );
          })}
        </Slider>
        {/* </div> */}
      </div>
    </content>
  );
}

export default Index;
