import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext, GlobalStateContext } from "../context";
import { API_SERVER } from "../constants";

function MembrosMembroDados({
  membro,
  lab,
  bond,
  mostrar_mais_button,
  mostrar_nome,
  is_tecnicos,
}) {
  const { t, i18n } = useTranslation();
  const [state, _] = useContext(LanguageContext);
  const [globalState, __] = useContext(GlobalStateContext);

  membro.interest_chosen =
    state.language === "pt"
      ? membro.academic.interest_pt
      : membro.academic.interest_en;
  membro.position_chosen =
    state.language === "pt"
      ? membro.academic.position_pt
      : membro.academic.position_en;

  // if (!is_tecnicos) {
  //   membro.interest_chosen =
  //     state.language === "pt"
  //       ? membro.academic.interest_pt
  //       : membro.academic.interest_en;
  //   membro.position_chosen =
  //     state.language === "pt"
  //       ? membro.academic.position_pt
  //       : membro.academic.position_en;
  // } else {
  //   membro.interest_chosen =
  //     state.language === "pt" ? membro.interest_pt : membro.interest_en;
  //   membro.position_chosen =
  //     state.language === "pt" ? membro.position_pt : membro.position_en;
  // }

  const default_lab_object = {
    name_en: "",
    name_pt: "",
    image: "",
    id: -1,
    is_main_lab: false,
    name_chosen: "",
    link: "",
  };

  lab = is_tecnicos ? default_lab_object : lab[0] ? lab : [default_lab_object];
  // lab = lab[0] ? lab : [default_lab_object];

  const mainLabs = is_tecnicos ? "" : lab.filter((l) => l.is_main_lab);
  const labToDisplay = is_tecnicos
    ? ""
    : mainLabs.length > 0
    ? [mainLabs[0]]
    : [lab[0]];

  if (!is_tecnicos) {
    labToDisplay.forEach(
      (l) => (l.name_chosen = state.language === "pt" ? l.name_pt : l.name_en)
    );

    labToDisplay.forEach((l) => (l.link = `/pesquisa/grupos/${l.id}`));
  }

  // if (membro.main_lab) {
  //   membro.main_lab.name_chosen =
  //     state.language === "pt"
  //       ? membro.main_lab.name_pt
  //       : membro.main_lab.name_en;
  // }

  const lab_content = is_tecnicos ? (
    ""
  ) : (
    <Fragment>
      {labToDisplay.map((l, index) => (
        <Link key={index} to={l.link}>
          {l.name_chosen}
        </Link>
      ))}
    </Fragment>
  );

  if (membro.academic.job) {
    membro.academic.job.label_chosen =
      state.language === "pt"
        ? membro.academic.job.label_pt
        : membro.academic.job.label_en;
  }

  const email = `mailto:${membro.academic.email}`;
  const membro_link = `/instituto/membros/${bond}/${membro.academic.short_name}`;

  let mais_button = (
    <div className="mais">
      <Link to={membro_link}>
        {t("SAIBA")} <i className="fa fa-plus-circle"></i>
      </Link>
    </div>
  );

  if (!mostrar_mais_button) {
    mais_button = "";
  }

  const is_pi = [
    "chefes_de_laboratorio",
    "diretoria",
    "Professor_fundador",
    "investigators",
    "directors",
  ].includes(bond);
  const is_staff = ["tecnicos", "technicians"].includes(bond);

  // const is_pi = bond === "chefes_de_laboratorio" || bond === "investigators";
  // const is_staff = bond === "tecnicos" || bond === "technicians";

  const publicacoes_content = "";

  const lab_part =
    lab_content !== "" ? (
      <Fragment>
        <strong>{t("Laboratório")}: </strong>
        {lab_content}
        <br />
      </Fragment>
    ) : (
      ""
    );

  const lattes_part = !membro.academic.lattes ? (
    ""
  ) : (
    <Fragment>
      <strong>Lattes: </strong>{" "}
      <a href={membro.academic.lattes} target="_blank">
        {membro.academic.lattes}
      </a>{" "}
      <br />
    </Fragment>
  );

  const email_part =
    membro.academic.email !== "" ? (
      <Fragment>
        <strong>E-mail: </strong> <a href={email}>{membro.academic.email}</a>{" "}
        <br />
      </Fragment>
    ) : (
      ""
    );

  const interest_part = membro.interest_chosen ? (
    <Fragment>
      <strong>{t("Áreas de interesse")}: </strong>
      {membro.interest_chosen}
    </Fragment>
  ) : (
    ""
  );

  const content = is_tecnicos ? (
    <Fragment>
      {email_part}
      {lattes_part}
      {lab_part}
      {interest_part}
    </Fragment>
  ) : (
    <Fragment>
      {lab_part}
      {lattes_part}
      {email_part}
      {interest_part}
    </Fragment>
  );

  let img_url;

  const image = membro.academic.image;
  const gender = membro.academic.gender;

  if (!image) {
    img_url =
      gender && gender.includes("M")
        ? `${globalState.image_server_base_url}/perfilmasculino.gif`
        : `${globalState.image_server_base_url}/perfilfeminino.gif`;
  } else if (image.includes("academic") || image.startsWith("/media/staff")) {
    img_url = `${API_SERVER}/${image}`;
  } else {
    const path = image.split("_imgs/");
    let img_path = path[path.length - 1];

    if (bond === "technicians" || bond === "tecnicos") {
      img_path = img_path.replace("/media/", "team/techs/");
    }

    img_url = `${globalState.image_server_base_url}/${img_path}`;
  }

  // const img_url = membro.image
  //   ? membro.image.replace(
  //       "/media/_imgs",
  //       "${API_SERVER}/static/core/_imgs/"
  //     )
  //   : membro.gender.includes("M")
  //   ? "${API_SERVER}/static/core/_imgs/perfilmasculino.gif"
  //   : "${API_SERVER}/static/core/_imgs/perfilfeminino.gif";

  return (
    <li>
      {/* <img src="/_imgs/perfilfeminino.gif" /> */}
      <img className="membro" src={img_url} alt="Member" />
      {mostrar_nome ? (
        <h4 className="membro_grupo">{membro.academic.name}</h4>
      ) : (
        ""
      )}
      <p>
        {is_pi ? membro.position_chosen : ""}
        {is_pi ? <br /> : ""}
        {is_staff ? (
          <Fragment>
            {membro.academic.job.label_chosen}
            <br />
          </Fragment>
        ) : (
          ""
        )}
        {content}
        <br />
      </p>
      {mais_button}
      <p></p>
    </li>
  );
}

export default MembrosMembroDados;
