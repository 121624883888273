import { API_SERVER, IMAGE_SERVER_BASE_URL } from "../constants";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { LanguageContext } from "../context";
import { Markup } from "interweave";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

function fixImageLinks(page) {
  return page.replace('src="/_imgs', `src="${IMAGE_SERVER_BASE_URL}`);
}

function HTMLPage() {
  const { software_name } = useParams();
  const location = useLocation();
  const pathname = location.pathname;

  const page_endpoint = `${API_SERVER}/api/v1/page?link=${pathname}`;

  const [pagina, setPagina] = useState(null);
  const [errorPagina, setErrorPagina] = useState(null);
  const [state, dispatch] = useContext(LanguageContext);

  // console.log('HTMLPage');

  const fetch_paginas = () => {
    // console.log('Fetching page....');
    fetch(page_endpoint)
      .then((res) => res.json())
      .then(
        (data) => {
          setPagina(data);
        },
        (error) => {
          setErrorPagina(error);
        }
      );
  };

  useEffect(() => {
    fetch_paginas();
  }, [pathname]);

  // console.log(software_name);
  // console.log(page_endpoint);
  // console.log(pagina);

  return (
    <content>
      <div id="centralizar_interna_html_page">
        <ConteudoInternasTitulo
          page_title={
            pagina &&
            (state.language === "pt"
              ? pagina.menu.label_pt
              : pagina.menu.label_en)
          }
        />
        <div className="conteudo_internas_html_page">
          <Markup
            content={
              pagina &&
              fixImageLinks(
                state.language === "pt"
                  ? pagina.body_text_pt
                  : pagina.body_text_en
              )
            }
          />
        </div>
      </div>
    </content>
  );
}

export default HTMLPage;
