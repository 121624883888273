import { API_SERVER } from "../constants";
import { useParams, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext, GlobalStateContext } from "../context";
import { Markup } from "interweave";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

function build_date(date) {
  const date_elements = date.split("-");
  return new Date(
    Number(date_elements[0]),
    Number(date_elements[1]) - 1,
    Number(date_elements[2])
  );
}

function EventosEvento() {
  const { evento_id } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({});
  const [errorEventData, setErrorEventData] = useState({});
  const [state, dispatch] = useContext(LanguageContext);
  const [globalState, __] = useContext(GlobalStateContext);
  const { t, _ } = useTranslation();

  const is_pt = state.language == "pt";
  const endpoint_events = `${API_SERVER}/api/v1/eventos/${evento_id}`;
  

  const fetch_event_data = () => {
    fetch(endpoint_events)
      .then((res) => res.json())
      .then(
        (data) => {
          setEventData(data);
          // console.log(eventData.image);

          if (eventData.image) {
            //let img_path = eventData.image.split("_imgs/");
            //img_path = img_url[img_url.length - 1];
            //img_url = `${globalState.image_server_base_url}/${eventData.image}`;
          }
        },
        (error) => {
          setErrorEventData(error);
        }
      );
  };

  useEffect(() => {
    fetch_event_data();
  }, []);

  // console.log(eventData.image);
  // console.log(img_url);


  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo
          page_title={is_pt ? eventData.title_pt : eventData.title_en}
        />
        <div className="conteudo_internas noticia">
          <span className="texto">
            <div className="area_imagem">
              <a href="#">
                <span className="imagem aumentar">
                  <img style={{ border: "none" }} src={`${globalState.API_SERVER}${eventData.image}`} />
                </span>
              </a>{" "}
              <span className="titulo_img"> </span>
            </div>

            <div style={{ width: "600px", float: "right" }}>
              <Markup content={is_pt ? eventData.text_pt : eventData.text_en} />
              <br />
              <div className="data_titulo">
                <b>{t("Data de Início")}:</b>{" "}
                {eventData.begin &&
                  build_date(eventData.begin).toLocaleDateString("pt-BR")}
              </div>
              <div className="data_titulo">
                <b>{t("Data de Termino")}:</b>{" "}
                {eventData.end &&
                  build_date(eventData.end).toLocaleDateString("pt-BR")}
              </div>
              <br />
              <b>{t("Localização")}:</b>{" "}
              <a
                href={
                  is_pt
                    ? eventData.location_pt &&
                      (eventData.location_pt.includes("http")
                        ? eventData.location_pt
                        : "")
                    : eventData.location_en &&
                      (eventData.location_en.includes("http")
                        ? eventData.location_en
                        : "")
                }
              >
                {is_pt ? eventData.location_pt : eventData.location_en}
              </a>
              <br />
              <b>{t("Site")}:</b>{" "}
              <a
                href={
                  is_pt
                    ? eventData.url_pt &&
                      (eventData.url_pt.includes("http")
                        ? eventData.url_pt
                        : "")
                    : eventData.url_en &&
                      (eventData.url_en.includes("http")
                        ? eventData.url_en
                        : "")
                }
              >
                {is_pt ? eventData.url_pt : eventData.url_en}
              </a>
            </div>
          </span>
        </div>
        <div className="voltar" style={{ float: "left" }}>
          <a onClick={() => navigate(-1)}>
            <i className="fa fa-arrow-circle-left"></i> {t("Anterior")}
          </a>
        </div>
      </div>
    </content>
  );
}

export default EventosEvento;
