import { Link } from "react-router-dom";

function MembrosMenuLink(props) {
  const link = `/instituto/membros/${props.member_link}`;

  return (
    <li>
      <Link to={link}>{props.nome}</Link>
    </li>
  );
}

export default MembrosMenuLink;
