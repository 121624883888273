import { API_SERVER } from "../constants";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { LanguageContext } from "../context";
import { deepcopy } from "../core";
import MembrosMenu from "./MembrosMenu";
import MembrosMembroDados from "./MembrosMembroDados";
import Paginacao from "./Paginacao";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

function Membros() {
  const { membro_type } = useParams();
  const [membroTipoInfo, setMembroTipoInfo] = useState([]);
  const [errorMembroTipoInfo, setErrorMembroTipoInfo] = useState([]);
  const [listaMembros, setListaMembros] = useState([]);
  const [errorListaMembros, setErrorListaMembros] = useState(null);
  const [membrosDisplay, setMembrosDisplay] = useState([]);
  const [numeroDeMembros, setNumeroDeMembros] = useState(0);
  const [membrosContent, setMembrosContent] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [errorNumeroDeMembros, setErrorNumeroDeMembros] = useState(null);
  const [state, dispatch] = useContext(LanguageContext);

  // console.log("***************************");
  // console.log(membro_type);
  // console.log(listaMembros);
  // console.log("-------");
  // console.log(membrosDisplay);

  let [searchParams, setSearchParams] = useSearchParams();
  let offset = 0;
  let limit = 11;

  if (searchParams.get("offset")) {
    offset = searchParams.get("offset");
  }

  const endpoint_membros = `${API_SERVER}/api/v1/instituto/membros/${membro_type}?limit=${limit}&offset=${offset}`;
  const endpoint_membros_tipo = `${API_SERVER}/api/v1/instituto/membros/${membro_type}/info`;

  const agglomerateObjects = (arr) => {
    const default_lab_object = {
      name_en: "",
      name_pt: "",
      image: "",
      id: -1,
      is_main_lab: false,
      name_chosen: "",
      link: "",
    };

    const aggObjects = arr.reduce((newObj, obj) => {
      if (!obj.lab) {
        obj.lab = deepcopy(default_lab_object);
      }

      if (Object.keys(newObj).includes(obj.academic.name)) {
        obj.lab.is_main_lab = obj.is_main_lab;
        newObj[obj.academic.name].lab.push(obj.lab);
      } else {
        newObj[obj.academic.name] = deepcopy(obj);
        obj.lab.is_main_lab = obj.is_main_lab;
        newObj[obj.academic.name].lab = [obj.lab];
      }
      return newObj;
    }, {});

    // console.log(aggObjects);

    return Object.values(aggObjects);
  };

  const fetch_members = () => {
    // console.log(`Fetching members for ${membro_type}`);
    fetch(endpoint_membros)
      .then((res) => res.json())
      .then(
        (data) => {
          // console.log(`FETCHED members for ${membro_type}`);
          const L = data.items.sort(
            (membro_i, membro_j) => membro_i.name > membro_j.name
          );
          setListaMembros(L);
          setNumeroDeMembros(data.count);
          const is_tecnicos =
            membro_type === "tecnicos" || membro_type === "technicians";

          const membrosToDisplay = !is_tecnicos ? agglomerateObjects(L) : L;

          setMembrosDisplay(membrosToDisplay);

          setMembrosContent(
            (!is_tecnicos
              ? membrosToDisplay
              : membrosToDisplay.filter((membro) => membro.academic.job)
            ).map((membro, index) => (
              <MembrosMembroDados
                key={index}
                membro={membro}
                lab={membro.lab}
                bond={membro_type}
                mostrar_mais_button={true}
                mostrar_nome={true}
                is_tecnicos={is_tecnicos}
              />
            ))
          );
        },
        (error) => {
          // console.log(`ERROR members for ${membro_type}`);
          setErrorListaMembros(error);
        }
      );
  };

  const fetch_members_type_info = () => {
    fetch(endpoint_membros_tipo)
      .then((res) => res.json())
      .then(
        (data) => {
          setMembroTipoInfo(data);
          setPageTitle(state.language === "pt" ? data.label_pt : data.label_en);
        },
        (error) => {
          setErrorMembroTipoInfo(error);
        }
      );
  };

  useEffect(() => {
    // console.log(`MEMBROS OFFSET - ${membro_type}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetch_members();
    fetch_members_type_info();
  }, [membro_type, offset]);

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={pageTitle} />
        <MembrosMenu />
        <div className="conteudo_internas documentos">
          <div className="membros">
            <ul>{membrosContent}</ul>
          </div>
        </div>
        <Paginacao
          baseURL={useLocation().pathname}
          pageCount={numeroDeMembros}
          offset={offset}
          limit={limit}
        />
      </div>
    </content>
  );
}

export default Membros;
