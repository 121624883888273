import { reducer, portugueseState, globalState } from "./reducer";
import { createContext, useReducer } from "react";

export const LanguageContext = createContext({
  state: portugueseState,
  dispatch: () => null,
});

export const GlobalStateContext = createContext({
  state: globalState,
  dispatch: () => null,
});

// export const LanguageProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(reducer, portugueseState);

//   return (
//     <LanguageContext.Provider value={[state, dispatch]}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };
