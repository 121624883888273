import sinfo_simbolo from "../static/img/sinfo_simbolo.png";

function Bottom() {
  return (
    <footer tabIndex="25">
      <a className="topo" href="#" title="Ir para o topo">
        <i className="fa fa-caret-up"></i>
      </a>

      <div className="rodape1">
        <section className="centraliza">
          {/* Dados do CE */}
          <section className="bloco">
            <strong>Instituto do Cérebro (ICe)</strong>
            <p className="font_rodape">
              Av. Senador Salgado Filho, 3000
              <br />
              Campus Universitário, Lagoa Nova - Natal / RN
              <br />
              59078-900, Caixa Postal: 1524
              <br />
              Fone: +55 84 99474-6607 / +55 84 99193-6311
              <br />
              E-mail: <a href="#">contato@neuro.ufrn.br</a>
            </p>
          </section>
          {/* <div className="separador"></div> */}
          {/* Parceiros */}
          {/* <section className="bloco">
          <strong>PARCEIROS</strong>
          <div className="parceiros">
            <a href="#">
              <i className="fa fa-chevron-left"></i>
            </a>
            <a href="#">
              {" "}
              <img src={banner_01} />{" "}
            </a>
            <a href="#">
              {" "}
              <img src={banner_01} />{" "}
            </a>
            <a href="#">
              <i className="fa fa-chevron-right"></i>
            </a>
          </div>
        </section> */}
          <div className="separador"></div>
          {/* Redes sociais */}
          <section className="bloco">
            <ul className="social">
              <li>
                <a className="facebook" href="https://www.facebook.com/Instituto-do-C%C3%A9rebro-UFRN-288347874699723/" target="_blank">
                  Facebook
                </a>
              </li>
              <li>
                <a className="youtube" href="https://www.youtube.com/iceufrn" target="_blank">
                  Youtube
                </a>
              </li>
              <li>
                <a className="flick" href="https://www.flickr.com/photos/institutodocerebro/albums" target="_blank">
                  Flick
                </a>
              </li>
              <li>
                <a
                  className="instagram"
                  href="https://www.instagram.com/iceufrn/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </section>
        </section>
      </div>
      {/* Rodape 2 */}
      <div className="rodape2">
        <div className="centraliza">
          <div className="direitos">
            Instituto do Cérebro - ICe &copy; Copyright 2015 - Todos os direitos
            reservados.
            <br />
            <a href="http://www.ufrn.br" target="_blank">
              UFRN - Universidade Federal do Rio Grande do Norte
            </a>
          </div>

          <div className="sinfo">
            <p>Desenvolvido por Coordenadoria de TI do ICe e </p>
            <a
              target="_blank"
              title="SINFO-UFRN"
              href="http://www.info.ufrn.br"
            >
              <img src={sinfo_simbolo} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Bottom;
