import { API_SERVER } from "../constants";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext, GlobalStateContext } from "../context";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

function DisciplinaCard({ disciplina, is_pt, globalState, t }) {
  const h4_style = { textTransform: "uppercase" };
  const disciplina_link = `/graduacao/Disciplinas/${disciplina.code}`;
  const disciplina_img_path = `${globalState.API_SERVER}/${disciplina.image}`;

  return (
    <li>
      <h4 style={h4_style}>
        {disciplina.code} - {is_pt ? disciplina.label_pt : disciplina.label_en}{" "}
        ({disciplina.hours}H)
      </h4>
      <img
        className="decore_image"
        src={disciplina_img_path}
        width="104"
        height="104"
      />
      <p>{is_pt ? disciplina.describe_pt : disciplina.describe_en}</p>
      <div className="mais">
        <Link to={disciplina_link}>
          {t("SAIBA")} <i className="fa fa-plus-circle"></i>
        </Link>
      </div>
    </li>
  );
}

function EnsinoGraduacaoDisciplinas() {
  const [disciplinas, setDisiciplinas] = useState([]);
  const [currentSemester, setCurrentSemester] = useState(0);
  const [errorDisciplinas, setErrorDisciplinas] = useState(null);
  const { t, _ } = useTranslation();
  const [state, dispatch] = useContext(LanguageContext);
  const [globalState, __] = useContext(GlobalStateContext);

  const is_pt = state.language == "pt";

  let suffix = useLocation().pathname.split("/")[2];

  switch (suffix) {
    case "disciplines":
      suffix = "Disciplinas";
    case "disciplinesOffered":
      suffix = "disciplinasOferecidas";
    default:
      suffix = suffix;
  }

  const endpoint_disciplinas = `${API_SERVER}/api/v1/graduacao/${suffix}`;

  // console.log(endpoint_disciplinas);

  const fetch_disciplinas = () => {
    fetch(endpoint_disciplinas)
      .then((res) => res.json())
      .then(
        (data) => {
          setDisiciplinas(data);
          const getYear = (season) => parseInt(season.label);
          const getPeriod = (season) => {
            let [_, period] = season.label.split(".");
            period = parseInt(period);
            return period;
          };
          const compareSeasons2 = (seasonA, seasonB) =>
            getYear(seasonA) > getYear(seasonB)
              ? -1
              : getYear(seasonA) === getYear(seasonB)
              ? getPeriod(seasonB) - getPeriod(seasonA)
              : 1;

          const disciplina_link = `${API_SERVER}/api/v1/turmas/${data[0].code}`;
          fetch(disciplina_link)
            .then((res) => res.json())
            .then(
              (data2) => {
                setCurrentSemester(data2.map(d => d.semestre).sort(compareSeasons2)[0].label);
              },
              (error) => {}
            );
        },
        (error) => {
          setErrorDisciplinas(error);
        }
      );
  };

  useEffect(() => {
    fetch_disciplinas();
  }, [suffix]);

  const disciplinas_content = disciplinas
    .sort(
      (d, e) =>
        (is_pt ? d.label_pt : d.label_en) > (is_pt ? e.label_pt : e.label_en)
    )
    .map((disciplina, index) => (
      <DisciplinaCard
        key={index}
        disciplina={disciplina}
        is_pt={is_pt}
        globalState={globalState}
        t={t}
      />
    ));

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo
          page_title={t(
            suffix.includes("Oferecidas")
              ? `DISCIPLINAS OFERTADAS (SEMESTRE ${currentSemester})`
              : "DISCIPLINAS"
          )}
        />
        <div className="conteudo_internas documentos">
          <div className="disciplinas">
            <ul>{disciplinas_content}</ul>
          </div>
        </div>
      </div>
    </content>
  );
}

export default EnsinoGraduacaoDisciplinas;
