import { API_SERVER } from "../constants";
import { repeat } from "lodash";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { LanguageContext } from "../context";
import { useContext } from "react";
import MembrosMenuLink from "./TopMenuMembrosLink";

class Graph {
  constructor(objects) {
    this.obj_list = objects;
    this.adj_list = {};

    this.obj_list.forEach((element) => (this.adj_list[element.id] = []));
    this.adj_list[null] = [];

    const addObject = (object) => {
      if (object.parent == null) {
        this.adj_list[null].push(object.id);
      } else {
        this.adj_list[object.parent.id].push(object.id);
      }
    };

    this.obj_list.forEach(addObject);
  }

  getChildrenOf(node_id) {
    return this.obj_list.filter((object) =>
      this.adj_list[node_id].includes(object.id)
    );
  }
}

function sortMenuItems(menu_items) {
  return menu_items.sort(
    (menu_item_i, menu_item_j) => menu_item_i.order - menu_item_j.order
  );
}

function BaseLinkLeafItem({ is_root, content }) {
  return is_root ? (
    <li>
      <label>{content}</label>
    </li>
  ) : (
    <li>{content}</li>
  );
}

function LinkLeafItem({ menu_item, is_root }) {
  const link_part = menu_item.link_chosen.includes("http") ? (
    <a href={menu_item.link_chosen}>{menu_item.label_chosen}</a>
  ) : (
    <Link to={menu_item.link_chosen}>{menu_item.label_chosen}</Link>
  );

  return <BaseLinkLeafItem is_root={is_root} content={link_part} />;
}

function MemberLinkLeafItem({ menu_item, is_root }) {
  const member_link = menu_item.link_chosen.split("/");
  const member_tag = member_link[member_link.length - 1];

  return (
    <MembrosMenuLink nome={menu_item.label_chosen} member_link={member_tag} />
  );
}

function LeafItem({ menu_item, is_root, level }) {
  // const tabs = repeat("\t", level);
  // console.log(`${tabs}${menu_item.label_pt}`);
  const ItemTag = menu_item.link_chosen.includes("membros")
    ? MemberLinkLeafItem
    : LinkLeafItem;
  return <ItemTag menu_item={menu_item} is_root={is_root} />;
}

function MenuItem({ menu_item, tree, level }) {
  // const tabs = repeat("\t", level);
  // console.log(`${tabs}${menu_item.label_pt}`);

  if (tree.adj_list[menu_item.id].length === 0) {
    if (menu_item.parent != null) {
      return <LeafItem menu_item={menu_item} is_root={false} level={level + 1} />;
    } else {
      return <LeafItem menu_item={menu_item} is_root={true} level={level + 1} />;
    }
  }

  const children = sortMenuItems(
    tree.obj_list.filter((obj) => tree.adj_list[menu_item.id].includes(obj.id))
  );

  const html = children.map((child) =>
    tree.adj_list[child.id].length === 0 ? (
      <LeafItem menu_item={child} is_root={false} level={level + 1} />
    ) : (
      <MenuItem menu_item={child} tree={tree} level={level + 1} />
    )
  );

  let list_part = <ol>{html}</ol>;
  let html_structure = (
    <li>
      <label>
        {menu_item.label_chosen}
        {list_part}
      </label>
    </li>
  );

  if ((level === 1) | (level === 3)) {
    list_part = <ul>{html}</ul>;
    html_structure = (
      <li>
        <label>{menu_item.label_chosen}</label>
        {list_part}
      </li>
    );
  }

  return html_structure;
}

function TopMenu() {
  const [state, dispatch] = useContext(LanguageContext);
  const [menu, setMenu] = useState([]);
  const [errorMenu, setErrorMenu] = useState({});

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/menu`)
      .then((res) => res.json())
      .then(
        (data) => {
          setMenu(
            data.filter((item) => item.enable).map((item) => {
              item.label_chosen =
                state.language === "pt" ? item.label_pt : item.label_en;
              item.link_chosen =
                state.language === "pt" ? item.link_pt : item.link_en;
              return item;
            })
          );
        },
        (error) => {
          setErrorMenu(error);
        }
      );
  }, [state]);

  const tree = new Graph(menu);

  // console.log(tree);

  const root_items = sortMenuItems(tree.getChildrenOf(null));

  const root_items_html = root_items.map((menu_item, index) => (
    <MenuItem key={index} menu_item={menu_item} tree={tree} level={0} />
  ));

  return (
    <nav>
      <div id="centralizar">
        <ul>{root_items_html}</ul>
      </div>
    </nav>
  );
}

export default TopMenu;
