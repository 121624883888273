import { API_SERVER } from "../constants";
import { useState, useEffect, Fragment } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../context";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { range } from "lodash";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import { deepcopy, formatDate, titleCase } from "../core";

function SearchMenuOption(option, callback_function, index) {
  return (
    <li
      key={index}
      className="search_menu"
      onClick={() => callback_function(option)}
    >
      {option}
    </li>
  );
}

function OportunidadeCard({ oportunidade, is_pt }) {
  const { t, __ } = useTranslation();

  const start_date = formatDate(
    oportunidade.start_date.replaceAll("-", "/"),
    is_pt
  );
  const end_date = formatDate(
    oportunidade.end_date.replaceAll("-", "/"),
    is_pt
  );

  const categories_content = oportunidade.category.map((category) => (
    <Fragment>
      <span className="green-card">
        {is_pt ? category.name_pt : category.name_en}
      </span>{" "}
    </Fragment>
  ));

  const status_frontname_mapping = {
    aberta: "Aberta",
    "em andamento": "em andamento",
    fechada: "Encerrada",
  };

  const status_style_mapping = {
    aberta: "green",
    "em andamento": "yellow",
    fechada: "black",
  };

  function titleCaseOportunidades(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      if (status_frontname_mapping[word]) 
      { 
        return status_frontname_mapping[word] 
      } 
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  const status_content = (
    <Fragment>
      <span
        className={`${status_style_mapping[oportunidade.status_pt]}-card`}
        style={{ marginLeft: 667 }}
      >
        {is_pt
          ? titleCaseOportunidades(oportunidade.status_pt)
          : titleCaseOportunidades(oportunidade.status_en)}
      </span>{" "}
    </Fragment>
  );
  const link_para_a_pagina = is_pt
    ? `/instituto/oportunidades/${oportunidade.id}`
    : `/institute/opportunities/${oportunidade.id}`;

  return (
    <li className="oportunidade_card">
      <span style={{ fontSize: "10px", marginLeft: "-10px" }}>
        Publicado em: {start_date}
      </span>
      {status_content}
      <h1 style={{ fontWeight: "600" }}>{is_pt ? oportunidade.title_pt : oportunidade.title_en}</h1>
      <spam>{is_pt ? oportunidade.subtitle_pt : oportunidade.subtitle_en}</spam>
      <br />
      {categories_content}
      <div className="mais">
        <Link to={{ pathname: link_para_a_pagina, state: { oportunidade } }}>
          {t("Saiba")} <i className="fa fa-plus-circle"></i>
        </Link>
      </div>
    </li>
  );
}

function Oportunidades() {
  const [state, dispatch] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const [oportunidades, setOportunidades] = useState([]);
  const [oportunidadesDisplay, setOportunidadesDisplay] = useState(null);
  const [oportunidadesContent, setOportunidadesContent] = useState(null);
  const [errorOportunidades, setErrorOportunidades] = useState(null);
  const [categoriasOportunidades, setCategoriasOportunidades] = useState([]);
  const [errorCategoriasOportunidades, setErrorCategoriasOportunidades] =
    useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    "Todas as categorias"
  );
  const [selectedYear, setSelectedYear] = useState("Todos os anos");
  const [selectedStatus, setSelectedStatus] = useState("Todos os status");

  const is_pt = state.language === "pt";
  let publicacoes_title = is_pt ? "Oportunidades" : "Opportunities";

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/instituto/oportunidades`)
      .then((res) => res.json())
      .then(
        (data) => {
          setOportunidades(data);
          setOportunidadesDisplay(data);
          setOportunidadesContent(
            data.map((info, index) => (
              <OportunidadeCard key={index} oportunidade={info} is_pt={is_pt} />
            ))
          );
        },
        (error) => {
          setErrorOportunidades(error);
        }
      );

    fetch(`${API_SERVER}/api/v1/instituto/oportunidades/categorias`)
      .then((res) => res.json())
      .then(
        (data) => {
          setCategoriasOportunidades(data);
        },
        (error) => {
          setErrorCategoriasOportunidades(error);
        }
      );

    setSelectedYear(is_pt ? "Todos os anos" : "All Years");
    setSelectedCategory(is_pt ? "Todas as categorias" : "All Categories");
    setSelectedStatus(is_pt ? "Todos os status" : "All Statuses");
  }, []);

  const filterByCategory = (option) => {
    
    setSelectedCategory(option);
  };

  const filterByYear = (option) => {
    setSelectedYear(option);
  };

  const filterByStatus = (option) => {
    setSelectedStatus(option);
  };

  useEffect(() => {
    
    let filteredOpportunities = deepcopy(oportunidades);

    if (
      !(selectedCategory.includes("Todas") || selectedCategory.includes("All"))
    ) {
      filteredOpportunities = filteredOpportunities.filter(
        (oportunidade) =>
          oportunidade.category.filter(
            (c) =>
              c.name_en === selectedCategory || c.name_pt === selectedCategory
          ).length > 0
      );
    }

    if (typeof selectedYear !== 'string') {
      filteredOpportunities = filteredOpportunities.filter(
        (oportunidade) =>
          parseInt(oportunidade.start_date.split("-")) ===
          selectedYear
      );
    }

    if (!(selectedStatus.includes("Todos") || selectedStatus.includes("All"))) {
      
      filteredOpportunities = filteredOpportunities.filter(
        (oportunidade) =>
          oportunidade.status_en === selectedStatus ||
          oportunidade.status_pt === (selectedStatus == "Encerrada" ? "fechada" : selectedStatus.toLowerCase())
      );
    }

    setOportunidadesContent(
      filteredOpportunities.map((info, index) => (
        <OportunidadeCard key={index} oportunidade={info} is_pt={is_pt} />
      ))
    );
  }, [selectedCategory, selectedYear, selectedStatus, is_pt, oportunidades]);
  
  //orderna as oportunidades por data de inicio
  oportunidades.sort((oportunidade1, oportunidade2) => oportunidade1.start_date < oportunidade2.start_date);

  // faz um map com todos os anos das oportunidades
  let years = oportunidades.map((oportunidade) =>( parseInt(oportunidade.start_date.split("-")[0])));
  // remove os anos duplicados
  years = [...new Set(years)];
  // ordena os anos
  years = years.sort((year1, year2) => year2 < year1);
  // adiciona a opção de todos os anos
  let year_title = is_pt ? "Todos os anos" : "All Years";
  let category_title = is_pt ? "Todas as categorias" : "All Categories";
  let status_title = is_pt ? "Todos os status" : "All Statuses";


  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={publicacoes_title} />
        <div className="conteudo_internas documentos">
          <div id="menu_grupo">
            <ul>
              <li>
                <label>{selectedCategory} </label>{" "}
                <i className="fa fa-caret-down"></i>
                <ul>
                  {[category_title]
                    .concat(
                      categoriasOportunidades.map((categoria) =>
                        is_pt ? categoria.name_pt : categoria.name_en
                      )
                    )
                    .map((categoria, index) =>
                      SearchMenuOption(categoria, filterByCategory, index)
                    )}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <label>{selectedYear} </label>{" "}
                <i className="fa fa-caret-down"></i>
                <ul>
                  {[year_title]
                    .concat(years.reverse())
                    .map((year, index) =>
                      SearchMenuOption(year, filterByYear, index)
                    )}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <label>{selectedStatus} </label>{" "}
                <i className="fa fa-caret-down"></i>
                <ul>
                  {[status_title]
                    .concat(
                      is_pt
                        ? ["Aberta", "Em andamento", "Encerrada"]
                        : ["Open", "In progress", "Closed"]
                    )
                    .map((status, index) =>
                      SearchMenuOption(status, filterByStatus, index)
                    )}
                </ul>
              </li>
            </ul>
          </div>
          <div className="publicacoes">
            <ul>{oportunidadesContent}</ul>
          </div>
        </div>
      </div>
    </content>
  );
}

export default Oportunidades;
