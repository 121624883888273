import { API_SERVER } from "../constants";
import { useParams } from "react-router";
import { useState, useEffect, Fragment } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext, GlobalStateContext } from "../context";
import { Link } from "react-router-dom";
import { Markup } from "interweave";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

function EnsinoGraduacaoDisciplinasPaginaDisciplina() {
  const { disciplina_codigo } = useParams();
  const { t, ___ } = useTranslation();

  const [disciplina, setDisiciplina] = useState({});
  const [errorDisciplina, setErrorDisciplina] = useState(null);
  const [turmas, setTurmas] = useState([]);
  const [errorTurmas, setErrorTurmas] = useState(null);
  const [state, _] = useContext(LanguageContext);
  const [globalState, __] = useContext(GlobalStateContext);

  const is_pt = state.language === "pt";
  const endpoint_disciplina = `${API_SERVER}/api/v1/graduacao/Disciplinas/${disciplina_codigo}`;
  const endpoint_turma = `${API_SERVER}/api/v1/turmas/${disciplina_codigo}`;

  const fetch_disciplinas = () => {
    fetch(endpoint_disciplina)
      .then((res) => res.json())
      .then(
        (data) => {
          setDisiciplina(data);
        },
        (error) => {
          setErrorDisciplina(error);
        }
      );
  };

  const fetch_turmas = () => {
    fetch(endpoint_turma)
      .then((res) => res.json())
      .then(
        (data) => {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const professors_list = element.professors.map((p) => p.name);
            data[index].professors = professors_list;
          }

          setTurmas(data);
        },
        (error) => {
          setErrorTurmas(error);
        }
      );
  };

  useEffect(() => {
    fetch_disciplinas();
    fetch_turmas();
  }, []);

  const convertDate = (date, is_pt) => {
    const [year, month, day] = date.split("-");
    return is_pt ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
  };

  const getYear = (season) => parseInt(season.label);
  const getPeriod = (season) => {
    let [_, period] = season.label.split(".");
    period = parseInt(period);
    return period;
  };

  const compareSeasons = (seasonA, seasonB) =>
    getYear(seasonA) > getYear(seasonB)
      ? 1
      : getYear(seasonA) === getYear(seasonB)
      ? getPeriod(seasonA) - getPeriod(seasonB)
      : -1;

  const compareSeasons2 = (seasonA, seasonB) =>
    getYear(seasonA) > getYear(seasonB)
      ? -1
      : getYear(seasonA) === getYear(seasonB)
      ? getPeriod(seasonB) - getPeriod(seasonA)
      : 1;

  const sortSeasons = (seasons) => seasons.sort(compareSeasons);

  // const disciplina_img_link = `/_imgs/disciplines/${disciplina_codigo}.jpg`;
  const disciplina_link = `/graduacao/Disciplinas/${disciplina_codigo}`;
  const disciplina_img_link =`${globalState.API_SERVER}/${disciplina.image}`;

  const room_name = is_pt ? disciplina.room_pt || "" : disciplina.room_en || "";

  const room_name_display = room_name === "" ? room_name : `- ${room_name}`;

  const disciplina_title = `${disciplina_codigo} - ${
    is_pt ? disciplina.label_pt : disciplina.label_en
  } (${disciplina.hours}H)`;

  const turmaAtual = turmas.sort((turmaA, turmaB) =>
    compareSeasons(turmaA.semestre, turmaB.semestre)
  )[turmas.length - 1];

  const infoComponent = (label, info) =>
    info ? (
      <Fragment>
        <h4>
          <strong>{label}:</strong> {info} <br />
        </h4>
      </Fragment>
    ) : (
      ""
    );

  const docenteComponent = (professors) =>
    professors
      ? professors.length > 1
        ? infoComponent("Docentes", professors.join(", "))
        : professors.length === 1
        ? infoComponent("Docente", professors[0])
        : infoComponent("", null)
      : infoComponent("", null);

  const currentYear = new Date().getFullYear().toString();

  const header_content =
    turmaAtual && turmaAtual.begin.includes(currentYear) ? (
      <Fragment>
        {infoComponent("Horário", turmaAtual.schedules)}
        {infoComponent("Sala", is_pt ? turmaAtual.room_pt : turmaAtual.sala_en)}
        {infoComponent("Início", convertDate(turmaAtual.begin, is_pt))}
        {infoComponent("Fim", convertDate(turmaAtual.end, is_pt))}
        {docenteComponent(turmaAtual.professors)}
      </Fragment>
    ) : (
      ""
    );

  const discipline_contents = (
    <Fragment>
      <h4>{t("Contéudos")}</h4>
      <Markup content={is_pt ? disciplina.content_pt : disciplina.content_en} />
    </Fragment>
  );

  const description_contents = (
    <Fragment>
      <h4>{t("Descrição")}</h4>
      <br />
      {is_pt ? disciplina.describe_pt : disciplina.describe_en}
      {/* <Markup
        content={is_pt ? disciplina.describe_pt : disciplina.describe_en}
      /> */}
    </Fragment>
  );

  const table_head_row = (label) => (
    <td>
      <b>{t(label)}</b>
    </td>
  );

  const joinProfessorsNames = (professors) => {
    // console.log(professors);
    return professors.map((professor, index) => <p key={index}>{professor}</p>);
    // return stringElements.join(<br />);
  };

  const printProfessors = (professors) =>
    professors
      ? professors.length > 1
        ? professors.reduce((accumulator, professor) => (
            <Fragment>
              {accumulator} <br /> {professor}
            </Fragment>
          ))
        : professors.length === 1
        ? professors[0]
        : ""
      : "";

  const seasons_table = turmas ? (
    <Fragment>
      <h4>{t("Histórico de ofertas")}</h4>
      <table>
        <thead>
          {table_head_row("Semestre")}
          {table_head_row("Horário")}
          {table_head_row("Início")}
          {table_head_row("Fim")}
          {table_head_row("Professor(es)")}
        </thead>
        <tbody>
          {turmas
            .sort((turmaA, turmaB) =>
              compareSeasons2(turmaA.semestre, turmaB.semestre)
            )
            .map((turma, key) => (
              <Fragment>
                {" "}
                <tr key={key} className={key % 2 === 0 ? 'even' : 'odd'}>
                  <td>{turma.semestre.label}</td>
                  <td>{turma.schedules === "" ? "-" : turma.schedules}</td>
                  <td>{convertDate(turma.begin, is_pt)}</td>
                  <td>{convertDate(turma.end, is_pt)}</td>
                  <td>{printProfessors(turma.professors)}</td>
                </tr>
              </Fragment>
            ))}
        </tbody>
      </table>
    </Fragment>
  ) : (
    ""
  );

  const page_title =
    turmaAtual && turmaAtual.semestre.label.includes(currentYear)
      ? `Disciplinas ofertadas (Semestre ${turmaAtual.semestre.label})`
      : "Disciplinas";

  return (
    <content>
      <div id="centralizar_interna">
        <Link to={disciplina_link}>
          <ConteudoInternasTitulo page_title={page_title} />
        </Link>
        <div className="conteudo_internas documentos">
          <h3
            style={{
              font: "24px Roboto Condensed, sans-serif",
            }}
          >
            {disciplina_title}
          </h3>{" "}
          <br />
          <div className="disciplinas">
            <div className="coluna1">
              {header_content}
              <br />
              {description_contents}
              <br />
              <br />
              {discipline_contents}
              <br />
              <br />
              {seasons_table}
            </div>
            <div className="coluna2">
              <img
                src={disciplina_img_link}
                alt={disciplina.name}
                width="154"
                height="154"
              />
            </div>
          </div>
        </div>
      </div>
    </content>
  );
}

export default EnsinoGraduacaoDisciplinasPaginaDisciplina;
