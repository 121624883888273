import { API_SERVER } from "../constants";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useContext } from "react";
import { membros_names_mapping } from "../constants";
import { Markup } from "interweave";
import { LanguageContext } from "../context";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import MembrosMembroDados from "./MembrosMembroDados";
import { deepcopy } from "../core";

function PaginaDoGrupoAreaMembros({ membros_tipo, membros, is_pt }) {
  const [membroTipoInfo, setMembroTipoInfo] = useState([]);
  const [errorMembroTipoInfo, setErrorMembroTipoInfo] = useState([]);
  const endpoint_membros_tipo = `${API_SERVER}/api/v1/instituto/membros/${membros_tipo}/info`;

  const fetch_members_type_info = () => {
    fetch(endpoint_membros_tipo)
      .then((res) => res.json())
      .then(
        (data) => {
          setMembroTipoInfo(data);
        },
        (error) => {
          setErrorMembroTipoInfo(error);
        }
      );
  };

  useEffect(() => {
    fetch_members_type_info();
  }, [membros_tipo]);

  const membros_content = membros.map((membro, index) => (
    <MembrosMembroDados
      key={index}
      membro={membro}
      lab={[membro.lab]}
      bond={membros_tipo}
      mostrar_mais_button={true}
      mostrar_nome={true}
      is_tecnicos={["tecnicos", "technicians"].includes(membros_tipo)}
    />
  ));

  const membroTitulo = is_pt
    ? membroTipoInfo.label_pt
    : membroTipoInfo.label_en;
  const piName = is_pt
    ? " e Chefes de laboratório"
    : " and Principal investigators";
  const addPI = ["Diretoria", "Directors"].includes(membroTitulo) ? piName : "";

  const exibir_membros =
    membros.length > 0 ? (
      <Fragment>
        <h4>
          {membroTitulo}
          {addPI}
        </h4>
        <ul>{membros_content}</ul>
      </Fragment>
    ) : (
      ""
    );

  return exibir_membros;
}

function GruposDePesquisaPaginaDoGrupo() {
  const { group_id } = useParams();

  const [bondInfo, setBondInfo] = useState([]);
  const [errorBondInfo, setErrorBondInfo] = useState([]);
  const [groupInfo, setGroupInfo] = useState({});
  const [errorGroup, setErrorGroupInfo] = useState({});
  const [groupBoss, setGroupBoss] = useState({});
  const [errorGroupBoss, setErrorGroupBoss] = useState({});
  const [groupMembers, setGroupMembers] = useState([]);
  const [errorGroupMembers, setErrorGroupMembers] = useState({});
  const [state, dispatch] = useContext(LanguageContext);

  const endpoint_base_url = `${API_SERVER}/api/v1/pesquisa/grupos/${group_id}`;

  const endpoint_group = endpoint_base_url;
  const endpoint_boss = `${endpoint_base_url}/boss`;
  const endpoint_members = `${endpoint_base_url}/membros`;

  const fetch_data = (endpoint, setFunction, setErrorFunction) => {
    fetch(endpoint)
      .then((res) => res.json())
      .then(
        (data) => {
          setFunction(data);
        },
        (error) => {
          setErrorFunction(error);
        }
      );
  };

  const endpoint = `${API_SERVER}/api/v1/instituto/membros`;

  const fetch_bond_info = () => {
    fetch(endpoint)
      .then((res) => res.json())
      .then(
        (data) => {
          setBondInfo(data);
        },
        (error) => {
          setErrorBondInfo(error);
        }
      );
  };

  useEffect(() => {
    fetch_bond_info();
  }, []);

  useEffect(() => {
    fetch_data(endpoint_group, setGroupInfo, setErrorGroupInfo);
    fetch_data(endpoint_boss, setGroupBoss, setErrorGroupBoss);
    fetch_data(endpoint_members, setGroupMembers, setErrorGroupMembers);
  }, []);

  const is_pt = state.language === "pt";

  const has_directors =
    groupMembers.filter((m) => m.bond.short_name_pt === "diretoria").length > 0;

  const members_order = bondInfo
    .sort((bondA, bondB) => bondA.order - bondB.order)
    .map((bond, index) => (is_pt ? bond.short_name_pt : bond.short_name_en));


  console.log(members_order);

  
  const agglomerateObjects = (arr) => {
    const aggObjects = arr.reduce((newObj, obj) => {
      if (Object.keys(newObj).includes(obj.academic.name)) {
        if (obj.is_main_lab) {
          newObj[obj.academic.name].lab = obj.lab;
        }
        if (obj.lab && obj.lab.id === parseInt(group_id)) {
          newObj[obj.academic.name].bond = obj.bond;
        }
      } else {
        newObj[obj.academic.name] = deepcopy(obj);
      }
      return newObj;
    }, {});

    return Object.values(aggObjects);
  };

  const conteudo_pagina = members_order.map((member_type, index) => (
    <PaginaDoGrupoAreaMembros
      key={index}
      membros_tipo={member_type}
      membros={agglomerateObjects(groupMembers).filter(
        (member) =>
          (is_pt ? member.bond.short_name_pt : member.bond.short_name_en) ===
          member_type
      )}
      is_pt={is_pt}
    />
  ));

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo
          page_title={is_pt ? groupInfo.name_pt : groupInfo.name_en}
        />
        <div className="conteudo_internas documentos">
          <div className="grupo">
            <ul>
              <li>
                <p>
                  <Markup
                    content={
                      is_pt ? groupInfo.summary_pt : groupInfo.summary_en
                    }
                  />
                </p>
                <br />
                {conteudo_pagina}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </content>
  );
}

export default GruposDePesquisaPaginaDoGrupo;
