import { API_SERVER } from "../constants";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../context";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { range } from "lodash";
import PesquisaPublicacoesPublicacao from "./PesquisaPublicacoesPublicacao";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import Paginacao from "./Paginacao";

function GrupoPesquisa(dados_do_grupo, year, index, is_pt) {
  let link_para_a_pagina = is_pt
    ? `/pesquisa/publicacoes/${dados_do_grupo.id}`
    : `/research/publication/${dados_do_grupo.id}`;

  if (year) {
    link_para_a_pagina = link_para_a_pagina.concat(`/${year}`);
  }

  return (
    <li key={index}>
      <Link to={link_para_a_pagina}>
        {is_pt ? dados_do_grupo.name_pt : dados_do_grupo.name_en}
      </Link>
    </li>
  );
}

function AnoPublicacao(year, group_id, index) {
  let link_para_o_ano = `/pesquisa/publicacoes/0/${year}`;

  if (group_id) {
    link_para_o_ano = `/pesquisa/publicacoes/${group_id}`;
    if (year != "Todos os anos") {
      link_para_o_ano = `${link_para_o_ano}/${year}`;
    }
  }
  return (
    <li key={index}>
      <Link to={link_para_o_ano}>{year}</Link>
    </li>
  );
}

function PesquisaPublicacoes() {
  const [gruposPesquisa, setGruposPesquisa] = useState([]);
  const [errorGruposPesquisa, setErrorGruposPesquisa] = useState(null);
  const [state, dispatch] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const is_pt = state.language === "pt";

  const submitFormHandle = (event) => {
    let searchPaper = event.target.value;
    if (searchPaper) {
      setSearchParams({ searchPaper });
    } else {
      setSearchParams({});
    }
  };

  const search_paper_term = searchParams.get("searchPaper");

  const todos_os_grupos = {
    id: 0,
    name_en: "All the groups",
    name_pt: "Todos os grupos",
    summary_en: "",
    summary_pt: "",
  };

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/pesquisa/grupos`)
      .then((res) => res.json())
      .then(
        (data) => {
          setGruposPesquisa([todos_os_grupos].concat(data.items));
        },
        (error) => {
          setErrorGruposPesquisa(error);
        }
      );
  }, []);

  const currentYear = new Date().getFullYear();

  let years = range(currentYear - 10, currentYear + 1, 1);

  const { group_id, year } = useParams();

  const [listaPublicacoes, setListaPublicacoes] = useState([]);
  const [numeroDePublicacoes, setNumeroDePublicacoes] = useState([]);
  const [errorListaPublicacoes, setErrorListaPublicacoes] = useState(null);

  const limit = 12;
  let offset = 0;
  if (searchParams.get("offset")) {
    offset = searchParams.get("offset");
  }

  let endpoint_publicacoes = `${API_SERVER}/api/v1/pesquisa/publicacoes`;

  let grupos_pesquisa_title = is_pt ? "Todos os grupos" : "All Groups";
  let year_title = is_pt ? "Todos os anos" : "All Years";
  let publicacoes_title = is_pt ? "Publicações" : "Publications";

  if (group_id) {
    endpoint_publicacoes = endpoint_publicacoes.concat(`/${group_id}`);
    const found_groups = gruposPesquisa.filter((grupo) => grupo.id == group_id);

    if (found_groups.length > 0 && group_id && group_id > 0) {
      grupos_pesquisa_title = is_pt
        ? found_groups[0].name_pt
        : found_groups[0].name_en;
      publicacoes_title = publicacoes_title.concat(
        ` - ${grupos_pesquisa_title}`
      );
    }
  }

  if (year) {
    if (!group_id) {
      endpoint_publicacoes = endpoint_publicacoes.concat(`/0`);
    }

    endpoint_publicacoes = endpoint_publicacoes.concat(`/${year}`);
    year_title = year;
  }

  if(!(group_id || year)){
    endpoint_publicacoes = endpoint_publicacoes.concat('/all');
  }

  endpoint_publicacoes = endpoint_publicacoes.concat(`?limit=${limit}&offset=${offset}`);

  const fetch_publications = () => {
    fetch(endpoint_publicacoes)
      .then((res) => res.json())
      .then(
        (data) => {
          setListaPublicacoes(data.items);
          setNumeroDePublicacoes(data.count);
        },
        (error) => {
          setErrorListaPublicacoes(error);
        }
      );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetch_publications();
  }, [group_id, endpoint_publicacoes]);

  useEffect(() => {
    fetch_publications();
  }, [year, endpoint_publicacoes]);

  let publicacoesObtidas = [];
  let conteudoPublicacoes = <h1>Sem publicações</h1>;
  
  if ((!errorListaPublicacoes) && (listaPublicacoes.length > 0)) {
    let publicacoesDigest = listaPublicacoes;

    if (search_paper_term) {
      publicacoesDigest = listaPublicacoes.filter((publicacao) =>
        publicacao.title_pt
          .toLowerCase()
          .includes(search_paper_term.toLowerCase())
      );
    }

    publicacoesObtidas = publicacoesDigest;
    conteudoPublicacoes = publicacoesObtidas.map((publicacao, index) => (
      <PesquisaPublicacoesPublicacao
        key={index}
        title={ is_pt? publicacao.title_pt : publicacao.title_en}
        pub_date={publicacao.date_publication}
        abstract={is_pt? publicacao.caption_pt :  publicacao.caption_en}
        pub_link={publicacao.url}
        pub_img={publicacao.thumbnail}
      />
    ));
  }

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={publicacoes_title} />
        <div className="conteudo_internas documentos">
          <div id="menu_grupo">
            <ul>
              <li>
                <label>{grupos_pesquisa_title}</label>{" "}
                <i className="fa fa-caret-down"></i>
                <ul>
                  {gruposPesquisa
                    .sort(
                      (e, f) =>
                        (is_pt ? e.name_pt : e.name_en).toLowerCase() >
                        (is_pt ? f.name_pt : f.name_en).toLowerCase()
                    )
                    .map((dados_do_grupo, index) =>
                      GrupoPesquisa(dados_do_grupo, year, index, is_pt)
                    )}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <label>{year_title} </label>{" "}
                <i className="fa fa-caret-down"></i>
                <ul>
                  {[year_title]
                    .concat(years.reverse())
                    .map((year, index) => AnoPublicacao(year, group_id, index))}
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                <label>{t("Buscar por Título")}</label>{" "}
                <i className="fa fa-caret-down"></i>
                <ul>
                  <li>
                    <form onSubmit={submitFormHandle}>
                      <input type="text" name="searchPaper" id="searchPaper" />
                    </form>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <br />
          <div className="publicacoes">
            <ul>{conteudoPublicacoes}</ul>
          </div>
        </div>
        <Paginacao
          baseURL={useLocation().pathname}
          pageCount={numeroDePublicacoes}
          offset={offset}
          limit={limit}
        />
      </div>
    </content>
  );
}

export default PesquisaPublicacoes;
