import { API_SERVER } from "../constants";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { deepcopy } from "../core";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import MembrosMembroDados from "./MembrosMembroDados";
import Paginacao from "./Paginacao";

function EnsinoGraduacaoProfessores() {
  const [listaDocentes, setListaDocentes] = useState([]);
  const [docentesContent, setDocentesContent] = useState(null);
  const [errorListaDocentes, setErrorListaDocentes] = useState(null);
  const { t, _ } = useTranslation();
  const endpoint_docentes = `${API_SERVER}/api/v1/graduacao/professores`;

  const agglomerateObjects = (arr) => {
    const default_lab_object = {
      name_en: "",
      name_pt: "",
      image: "",
      id: -1,
      is_main_lab: false,
      name_chosen: "",
      link: "",
    };

    const aggObjects = arr.reduce((newObj, obj) => {
      if (!obj.lab) {
        obj.lab = deepcopy(default_lab_object);
      }

      if (Object.keys(newObj).includes(obj.academic.name)) {
        obj.lab.is_main_lab = obj.is_main_lab;
        newObj[obj.academic.name].lab.push(obj.lab);
      } else {
        newObj[obj.academic.name] = deepcopy(obj);
        obj.lab.is_main_lab = obj.is_main_lab;
        newObj[obj.academic.name].lab = [obj.lab];
      }
      return newObj;
    }, {});

    // console.log(aggObjects);

    return Object.values(aggObjects);
  };

  const fetch_professors = () => {
    fetch(endpoint_docentes)
      .then((res) => res.json())
      .then(
        (data) => {
          setListaDocentes(data);
          setDocentesContent(
            agglomerateObjects(data)
              .sort((docente_a, docente_b) => docente_a.name > docente_b.name)
              .map((membro, index) => (
                <MembrosMembroDados
                  key={index}
                  membro={membro}
                  lab={membro.lab}
                  bond="chefes_de_laboratorio"
                  mostrar_mais_button={true}
                  mostrar_nome={true}
                  is_tecnicos={false}
                />
              ))
          );
        },
        (error) => {
          setErrorListaDocentes(error);
          setDocentesContent(
            <h3>
              ERRO: Não foi possível carregar os dados de docentes. Tente
              novamente mais tarde.
            </h3>
          );
        }
      );
  };

  useEffect(() => {
    fetch_professors();
  }, []);

  // const docentes_content = listaDocentes
  //   .sort((docente_a, docente_b) => docente_a.name > docente_b.name)
  //   .map((membro, index) => (
  //     <MembrosMembroDados
  //       key={index}
  //       membro={membro}
  //       lab={membro.lab}
  //       bond="chefes_de_laboratorio"
  //       mostrar_mais_button={true}
  //       mostrar_nome={true}
  //       is_tecnicos={false}
  //     />
  //   ));

  // if (errorListaDocentes) {
  //   docentes_content = (
  //     <h3>
  //       ERRO: Não foi possível carregar os dados de docentes. Tente novamente
  //       mais tarde.
  //     </h3>
  //   );
  // }

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={t("Professores da Graduação")} />
        <div className="conteudo_internas documentos">
          <div className="membros">
            <ul>{docentesContent}</ul>
          </div>
        </div>
        <Paginacao />
      </div>
    </content>
  );
}

export default EnsinoGraduacaoProfessores;
