import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalStateContext } from "../context";

function PesquisaPublicacoesPublicacao(props) {
  const [globalState, _] = useContext(GlobalStateContext);
  const { t, __ } = useTranslation();
  const default_image_path = "img_pagina.jpg";

  let image_suffix;
  let image_path;
  let full_img_path;

  if (!props.pub_img){
    full_img_path = `${globalState.image_server_base_url}/${default_image_path}`;
  } else if (props.pub_img.includes("_imgs")) {
    image_suffix = props.pub_img.split("_imgs/")[1];
    image_path = image_suffix ? image_suffix : default_image_path;
    full_img_path = `${globalState.image_server_base_url}/${image_path}`;
  } else if (props.pub_img.includes("/media")){
    full_img_path = `${globalState.API_SERVER}${props.pub_img}`;
  } else {
    full_img_path = `${globalState.image_server_base_url}/${default_image_path}`;
  }

  return (
    <li>
      <h4>{props.title}</h4>
      <img alt={""} src={full_img_path} />
      {props.pub_date}
      <br />
      {props.abstract}
      <div className="mais">
        <a target="_blank" rel="noopener noreferrer" href={props.pub_link}>
          {t("Leia")} <i className="fa fa-plus-circle"></i>
        </a>
      </div>
    </li>
  );
}

export default PesquisaPublicacoesPublicacao;
