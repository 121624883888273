import { API_SERVER } from "./constants";

export const reducer = (state, language) => {
  return language;
};

export const globalStateReducer = (state, new_state) => {
  return new_state;
};

export const globalState = {
  image_server_base_url: `${API_SERVER}/static/core/_imgs`,
  media_image_server_base_url: `${API_SERVER}/media`,
  API_SERVER,
};

export const portugueseState = {
  language: "pt",
};

export const englishState = {
  language: "en",
};
