import { API_SERVER } from "../constants";
import { MemoryRouter, useParams } from "react-router";
import { useState, useEffect, Fragment } from "react";
import { useContext } from "react";
import { LanguageContext } from "../context";
import MembrosMembroDados from "./MembrosMembroDados";
import MembrosDetalhesBarraOrientador from "./MembrosDetalhesBarraOrientador";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import { Markup } from "interweave";
import MembrosDetalhesPublicacoes from "./MembrosDetalhesPublicacoes";

// function MembrosDetalhesPublicacao({ publicacao }) {
//   const { t, i18n } = useTranslation();

//   return (
//     <li>
//       <p>
//         {publicacao.ref}
//         <br />{" "}
//         <a href={publicacao.url} target="_blank">
//           <b>{t("Texto Completo")}</b>{" "}
//         </a>
//       </p>
//     </li>
//   );
// }

function MembrosDetalhes() {
  const { membro_type, membro_name } = useParams();
  const [dadosMembro, setDadosMembro] = useState({});
  const [errorDadosMembro, setErrorDadosMembro] = useState({});
  const [mostrarPublicacoes, setMostrarPublicacoes] = useState(false);
  const [state, _] = useContext(LanguageContext);
  const [membroDisplay, setMembroDisplay] = useState(null);

  const endpoint_dados_membro = `${API_SERVER}/api/v1/instituto/membros/${membro_type}/${membro_name}`;
  const is_pi = [
    "chefes_de_laboratorio",
    "Professor_fundador",
    "investigators",
  ].includes(membro_type);
  const is_tecnicos = ["tecnicos", "technicians"].includes(membro_type);

  const fetch_member_data = () => {
    fetch(endpoint_dados_membro)
      .then((res) => res.json())
      .then(
        (data) => {
          setDadosMembro(data);

          if (!is_tecnicos) {
            const membro_main_lab = data.filter((membro) => membro.is_main_lab);
            setMembroDisplay(
              membro_main_lab.length > 0 ? membro_main_lab[0] : data[0]
            );
          } else {
            setMembroDisplay(data[0]);
          }

          if ((is_pi) || (is_tecnicos)) {
            setMostrarPublicacoes(true);
          } else {
            setMostrarPublicacoes(false);
          }
        },
        (error) => {
          setErrorDadosMembro(error);
        }
      );
  };

  let publications = "";

  useEffect(() => {
    fetch_member_data();
  }, [membro_name]);

  let content = "";

  if (membroDisplay) {
    content = (
      <div id="centralizar_interna">
        {"academic" in membroDisplay ? (
          <ConteudoInternasTitulo page_title={membroDisplay.academic.name} />
        ) : (
          <ConteudoInternasTitulo page_title={membroDisplay.name} />
        )}
        <div className="conteudo_internas documentos">
          {is_pi ? (
            <MembrosDetalhesBarraOrientador pi_name={membro_name} />
          ) : (
            ""
          )}
          <div className="membros">
            <ul>
              <MembrosMembroDados
                membro={membroDisplay}
                lab={[membroDisplay.lab]}
                bond={membro_type}
                mostrar_mais_button={false}
                is_tecnicos={is_tecnicos}
                mostrar_nome={false}
              />
            </ul>

            <p>
              <Markup
                content={
                  "academic" in membroDisplay
                    ? state.language === "pt"
                      ? membroDisplay.academic.profile_pt
                      : membroDisplay.academic.profile_en
                    : state.language === "pt"
                    ? membroDisplay.profile_pt
                    : membroDisplay.profile_en
                }
              />
            </p>
            <br />
            {mostrarPublicacoes ? (
              <MembrosDetalhesPublicacoes
                dadosMembro={membroDisplay.academic}
                is_tecnicos={is_tecnicos}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  } else {
    console.log("NULO");
  }

  return <content>{content}</content>;
}

export default MembrosDetalhes;
