import { API_SERVER } from "../constants";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

function MembrosDetalhesPublicacao({ publicacao }) {
  const { t, i18n } = useTranslation();
  return (
    <li>
      <p>
        {publicacao.ref}
        <br />{" "}
        <a href={publicacao.url} target="_blank">
          <b>{t("Texto Completo")}</b>{" "}
        </a>
      </p>
    </li>
  );
}

function MembrosDetalhesPublicacoes({ dadosMembro, is_tecnicos }) {
  // console.log(dadosMembro);

  const [publicacoesMembro, setPublicacoesMembro] = useState([]);
  const [errorPublicacoesMembro, setErrorPublicacoesMembro] = useState({});
  const [tesesMembro, setTesesMembro] = useState([]);
  const [errorTesesMembro, setErrorTesesMembro] = useState({});
  const { t, _ } = useTranslation();

  const fetch_member_publications = () => {
    const endpoint_publications = is_tecnicos
      ? `${API_SERVER}/api/v1/pesquisa/publicacoes/tecnicos/${dadosMembro.short_name}`
      : `${API_SERVER}/api/v1/pesquisa/publicacoes/${dadosMembro.main_lab.id}`;
    // console.log(endpoint_publications);
    fetch(endpoint_publications)
      .then((res) => res.json())
      .then(
        (data) => {
          setPublicacoesMembro(data.items);
        },
        (error) => {
          setErrorPublicacoesMembro(error);
        }
      );
  };

  const fetch_member_theses = (lab_id) => {
    if (!is_tecnicos) {
      const endpoint_theses = `${API_SERVER}/api/v1/pesquisa/teses/${dadosMembro.main_lab.id}`;
      // console.log(endpoint_theses);
      fetch(endpoint_theses)
        .then((res) => res.json())
        .then(
          (data) => {
            setTesesMembro(data);
          },
          (error) => {
            setErrorTesesMembro(error);
          }
        );
    }
  };

  useEffect(fetch_member_publications, []);
  useEffect(fetch_member_theses, []);

  // console.log(`#Artigos: ${publicacoesMembro.length}`);
  // console.log(`#Teses: ${tesesMembro.length}`);

  return (
    <Fragment>
      {!is_tecnicos ? <br /> : ""}
      {publicacoesMembro.length > 0 ? (
        <div id="publications">
          <h2>{t("Publicações")}</h2>
          <ul>
            <br />
            {publicacoesMembro.map((publicacao, index) => (
              <MembrosDetalhesPublicacao publicacao={publicacao} key={index} />
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
      {!is_tecnicos ? (
        <div id="thesis">
          <h2>{t("Teses")}</h2>
          <ul>
            <br />
            {tesesMembro.map((tese, index) => (
              <MembrosDetalhesPublicacao publicacao={tese} key={index} />
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default MembrosDetalhesPublicacoes;
