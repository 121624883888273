import { API_SERVER } from "../constants";
import { useLocation, useParams } from "react-router";
import { useState, useEffect, Fragment } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext, GlobalStateContext } from "../context";
import { Link } from "react-router-dom";
import { Markup } from "interweave";
import { formatDate, titleCase } from "../core";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

function MediaTable({ media_files, is_pt }) {
  return (
    <table className="oportunidade_arquivos">
      <tbody>
        {media_files.map((media_file, index) => (
          <Fragment>
            {" "}
            <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
              <td>{formatDate(media_file.date.replaceAll("-", "/"), is_pt)}</td>
              <td>{is_pt ? media_file.title_pt : media_file.title_en}</td>
              <td>
                <a
                  href={`${API_SERVER}${media_file.associated_file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className="fa fa-download"
                    style={{ color: "#16a06b" }}
                  ></span>
                </a>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}

function OportunidadesPaginaOportunidade() {
  const { oportunidade_codigo } = useParams();
  const { t, ___ } = useTranslation();

  const [oportunidade, setOportunidade] = useState({});
  const [errorOportunidade, setErrorOportunidade] = useState(null);
  const [oportunidadeMedia, setOportunidadeMedia] = useState([]);
  const [errorOportunidadeMedia, setErrorOportunidadeMedia] = useState(null);
  const [state, _] = useContext(LanguageContext);
  const [globalState, __] = useContext(GlobalStateContext);

  const is_pt = state.language === "pt";
  const endpoint_oportunidade = `${API_SERVER}/api/v1/instituto/oportunidades/busca/${oportunidade_codigo}`;
  const endpoint_arquivos = `${API_SERVER}/api/v1/instituto/oportunidades/${oportunidade_codigo}/files`;

  const fetch_oportunidade = () => {
    fetch(endpoint_oportunidade)
      .then((res) => res.json())
      .then(
        (data) => {
          setOportunidade(data);
        },
        (error) => {
          setErrorOportunidade(error);
        }
      );
  };

  const fetch_oportunidade_media = () => {
    fetch(endpoint_arquivos)
      .then((res) => res.json())
      .then(
        (data) => {
          setOportunidadeMedia(data);
        },
        (error) => {
          setErrorOportunidadeMedia(error);
        }
      );
  };

  useEffect(() => {
    fetch_oportunidade();
    fetch_oportunidade_media();
  }, []);

  const page_title = is_pt ? oportunidade.title_pt : oportunidade.title_en;
  const page_subtitle = is_pt
    ? oportunidade.subtitle_pt
    : oportunidade.subtitle_en;

  const categories_content = oportunidade.category
    ? oportunidade.category.map((category) => (
        <Fragment>
          <span className="green-card">
            {is_pt ? category.name_pt : category.name_en}
          </span>{" "}
        </Fragment>
      ))
    : "";

  const status_content = oportunidade.status_pt ? (
    <Fragment>
      <span
        className={`green-card`}
      >
        {is_pt
          ? titleCase(oportunidade.status_pt)
          : titleCase(oportunidade.status_en)}
      </span>{" "}
    </Fragment>
  ) : "";

  const description_content = is_pt
    ? oportunidade.description_pt
    : oportunidade.description_en;

  const start_date = oportunidade.start_date
    ? formatDate(oportunidade.start_date.replaceAll("-", "/"), is_pt)
    : "";
  const end_date = oportunidade.end_date
    ? formatDate(oportunidade.end_date.replaceAll("-", "/"), is_pt)
    : "";

  const other_info = is_pt
    ? oportunidade.other_info_pt
    : oportunidade.other_info_en;

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={page_title} />
        <div className="conteudo_internas documentos">
          <h4
            style={{
              fontWeight: "600",
              margin: 0,
            }}
          >
            {page_subtitle}
          </h4>
          <br />
          {categories_content} {status_content}
          <br />
          <Markup content={description_content} />
          <br />
          <p> </p>
          <h4 style={{fontWeight: "600", color: "#16a06b" }}>{t("Período do Processo")}</h4>
          <br />
          {start_date} - {end_date}
          <br />
          <br />
          
          {other_info && other_info !== "" ? (
            <Fragment>
              <br />
              <h4 style={{ fontWeight: "600", color: "#16a06b" }}>{t("Outras informações")}</h4>
              
              <Markup
                content={
                  is_pt
                    ? oportunidade.other_info_pt
                    : oportunidade.other_info_en
                }
              />
            </Fragment>
          ) : (
            ""
          )}
          <br />
          <br />
          <MediaTable media_files={oportunidadeMedia} is_pt={is_pt} />
        </div>
      </div>
    </content>
  );
}

export default OportunidadesPaginaOportunidade;
