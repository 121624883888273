// const API_SERVER = "http://localhost:8000";
const API_SERVER = "https://neuro.ufrn.br";
const WORDPRESS_ENDPOINT =
  "https://neuro.ufrn.br/blog/?rest_route=/wp/v2/posts";
const STANDARD_THUMBNAIL = `${API_SERVER}/static/core/_imgs/img_pagina.jpg`;
const IMAGE_SERVER_BASE_URL = `${API_SERVER}/static/core/_imgs`;


const membros_names_mapping = {
  chefes_de_laboratorio: "Chefes de laboratório",
  pos_docs: "Pós-doutorandos",
  doutorandos: "Doutorandos",
  mestrandos: "Mestrandos",
  residentes: "Residentes",
  graduandos: "Estudantes de graduação",
  colaboradores: "Colaboradores",
  // tecnicos: "Corpo Técnico",
  ensino_medio: "Ensino Médio",
  gerente_de_laboratorio: "Gerente de Laboratório",
  anteriores: "Membros anteriores",
  diretoria: "Diretoria",
  tecnicos: "Equipe Técnica",
  visitantes: "Pesquisador Visitante",

  // "investigators"
  // "technicians"
  // "post_docs"
  // "phd_students"
  // "msc_students"
  // "residents"
  // "undergrads"
  // "collaborators"
  // "visiting"

  investigators: "Chefes de laboratório",
  post_docs: "Pós-doutorandos",
  phd_students: "Doutorandos",
  msc_students: "Mestrandos",
  residents: "Residentes",
  undergrads: "Estudantes de graduação",
  collaborators: "Colaboradores",
  high_school: "Ensino Médio",
  lab_manager: "Gerente de Laboratório",
  previous: "Membros anteriores",
  directors: "Diretoria",
  technicians: "Equipe Técnica",
  visiting: "Pesquisador Visitante",
};

export {
  membros_names_mapping,
  API_SERVER,
  WORDPRESS_ENDPOINT,
  STANDARD_THUMBNAIL,
  IMAGE_SERVER_BASE_URL
};
