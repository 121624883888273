import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WORDPRESS_ENDPOINT } from "../constants";
import { formatDate } from "../core";

function IndexICeNaMidia() {
  const { t, i18n } = useTranslation();
  const [noticias, setNoticias] = useState([]);
  const [errorNoticias, setErrorNoticias] = useState(null);
  const is_pt = i18n.language === "pt";

  useEffect(() => {
    fetch(WORDPRESS_ENDPOINT)
      .then((res) => res.json())
      .then(
        (data) => {
          setNoticias(data);
        },
        (error) => {
          setErrorNoticias(error);
        }
      );
  }, []);

  let news_message = <h3>{t("Mensagem de erro")}</h3>;

  if (!errorNoticias) {
    news_message = noticias.slice(0, 5).map((noticia, index) => (
      <li>
        <strong style={{ fontSize: "12px", fontWeight: "normal" }}>
          {t("Publicado em:")} {formatDate(noticia.date, is_pt)}
        </strong>{" "}
        <a target="_blank" rel="noopener noreferrer" href={noticia.link}>
          {noticia.title.rendered}
        </a>
      </li>
    ));
  }

  return (
    <div className="midia">
      <div
        className="titulo_index_midia"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            backgroundColor: "#cccd5f",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            marginLeft: "-30px",
          }}
        ></div>
        <h3 style={{ marginLeft: "auto" }}>{t("Notícias")}</h3>
      </div>
      <ul>{news_message}</ul>
      <div className="mais">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://neuro.ufrn.br/blog"
        >
          {t("SAIBA")} <i className="fa fa-plus-circle"></i>
        </a>
      </div>
    </div>

    // <div className="midia">
    //   <div className="titulo_index_midia">
    //     <h3>{t("Notícias")}</h3>
    //   </div>
    //   <ul>{news_message}</ul>
    //   <div className="mais">
    //     <a target="_blank" rel="noopener noreferrer" href="https://neuro.ufrn.br/blog">
    //       {t("SAIBA")} <i className="fa fa-plus-circle"></i>
    //     </a>
    //   </div>
    // </div>
  );
}

export default IndexICeNaMidia;
