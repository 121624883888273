import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      Saiba: "Know",
      Laboratório: "Laboratory",
      "Áreas de interesse": "Areas of interest",
      "Chefes de laboratório": "Principal investigators",
      Membros: "Members",
      Publicações: "Publications",
      Teses: "Theses",
      "Texto Completo": "Full Text",
      Orientador: "Supervisor",
      "Buscar por Título": "Search by Title",
      "Professores da Graduação": "Faculty",
      DISCIPLINAS: "DISCIPLINES",
      "DISCIPLINAS OFERECIDAS": "OFFERED DISCIPLINES",
      Eventos: "Events",
      "Eventos Abertos": "Opened Events",
      "Eventos Encerrados": "Closed Events",
      "Data de Início": "Date start",
      "Data de Termino": "Date end",
      Localização: "Location",
      Site: "Web site",
      Anterior: "Back",
      "Mensagem de erro":
        "ERROR: Failed to load publications. Try again later.",
      "Mensagem de erro eventos":
        "ERROR: Failed to load events. Try again later.",
      "Painel COVID-19": "Panel COVID-19",
      "Ice na mídia": "In the media",
      Notícias: "In the media",
      Veja: "See",
      SAIBA: "SEE",
      Leia: "Read",
      EVENTOS: "EVENTS",
      Contéudos: "Contents",
      Horários: "Schedules",
      Semestre: "Semester",
      Horário: "Schedule",
      Início: "Begin",
      Fim: "End",
      "Professor(es)": "Professor(s)",
      "Histórico de ofertas": "Course offerings history",
      "Descrição": "Description",
      "Período do Processo": "Selection duration",
      "Outras informações": "Other information",
      "Publicado em:": "Published in:",
    },
  },
  pt: {
    translation: {
      "Welcome to React": "Bem-vindo ao React!",
      Saiba: "Saiba",
      SAIBA: "SAIBA",
      Leia: "Leia",
      Laboratório: "Laboratório",
      "Áreas de interesse": "Áreas de interesse",
      "Chefes de laboratório": "Chefes de laboratório",
      Membros: "Membros",
      Publicações: "Publicações",
      Teses: "Teses",
      "Texto Completo": "Texto Completo",
      Orientador: "Orientador",
      "Buscar por Título": "Buscar por Título",
      "Professores da Graduação": "Professores da Graduação",
      DISCIPLINAS: "DISCIPLINAS",
      "DISCIPLINAS OFERECIDAS": "DISCIPLINAS OFERECIDAS",
      Eventos: "Eventos",
      EVENTOS: "EVENTOS",
      "Eventos Abertos": "Eventos Abertos",
      "Eventos Encerrados": "Eventos Encerrados",
      "Data de Início": "Data de Início",
      "Data de Termino": "Data de Termino",
      Localização: "Localização",
      Notícias: "Notícias",
      Site: "Site",
      Anterior: "Anterior",
      "Mensagem de erro":
        "ERRO: Não foi possível carregar as publicações. Tente novamente mais tarde.",
      "Mensagem de erro eventos":
        "ERRO: Não foi possível carregar os eventos. Tente novamente mais tarde.",
      "Painel COVID-19": "Painel COVID-19",
      "Ice na mídia": "Ice na mídia",
      Veja: "Veja",
      Contéudos: "Contéudos",
      Horários: "Horários",
      "Histórico de ofertas": "Histórico de ofertas",
      Semestre: "Semestre",
      Horário: "Horário",
      Início: "Início",
      Fim: "Fim",
      "Professor(es)": "Professor(es)",
      "Descrição": "Descrição",
      "Período do Processo": "Período do Processo",
      "Outras informações": "Outras informações",
      "Publicado em:": "Publicado em:",
    },
  },
};

const browserLanguage = navigator.language || navigator.userLanguage;
const initLanguage = browserLanguage.includes("pt") ? "pt" : "en";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: initLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
