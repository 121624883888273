import { handleThumbnail } from "../core";
import { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../context";

// function handleThumbnail(image) {
//   if (!image) {
//     return null;
//   }
//   const image_path = image.split("_imgs/")[1];
//   return `${IMAGE_SERVER_BASE_URL}/${image_path}`;
// }

function IndexSlideshow({ paper_big_picture, papers }) {
  const [bigSlide, setBigSlide] = useState({});
  const [counter, setCounter] = useState(0);
  const [carrossel, setCarrossel] = useState(null);
  const countRef = useRef(counter);
  const [state, dispatch] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  countRef.current = counter;

  const is_pt = state.language === "pt";

  const changeBigSlide = (publicacao, index) => {
    if (publicacao) {
      publicacao.image = handleThumbnail(publicacao.image);
      // let currCount = countRef.current;
      setBigSlide(publicacao);
      setCounter(index);
    }
  };

  useEffect(() => {
    setBigSlide(paper_big_picture);
  }, [paper_big_picture]);

  useEffect(() => {
    if (carrossel) {
      clearInterval(carrossel);
    }
    setCarrossel(
      setInterval(() => {
        let currCount = countRef.current;
        changeBigSlide(papers[currCount], currCount);
        setCounter((contador) => (contador + 1) % 5);
      }, 6000)
    );
    return () => clearInterval(carrossel);
  }, [papers]);

  const conteudo = papers.map((publicacao, index) => (
    <li key={index}>
      <a onClick={(e) => changeBigSlide(publicacao, index)}>
        <img
          alt={publicacao.title_en}
          src={handleThumbnail(publicacao.image)}
        />
      </a>
    </li>
  ));

  return (
    <div className="slide_noticias">
      <div className="imagem_maior">
        <a target="_blank" rel="noopener noreferrer" href={bigSlide.url_en}>
          <img src={handleThumbnail(bigSlide.image)} alt={bigSlide.title_en} />
          <div className="titulo_img_maior">
            <p>{is_pt ? bigSlide.title_pt : bigSlide.title_en}</p>
          </div>
        </a>
      </div>
      <div className="mais_noticias">
        <ul>{conteudo}</ul>
      </div>
    </div>
  );
}

export default IndexSlideshow;
