import Top from "./Top";
import Bottom from "./Bottom";
import { Outlet } from "react-router";
import { Fragment } from "react";

function Home() {
  return (
    <Fragment>
      <Top />
      <Outlet />
      <Bottom />
    </Fragment>
  );
}

export default Home;
