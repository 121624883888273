function ConteudoInternasTitulo({ page_title }) {
  const title_length = page_title ? page_title.length : 0;
  const new_style = title_length < 50 ? {} : { fontSize: "17px" };

  return (
    <div className="titulo_internas">
      <h3 style={new_style}>{page_title}</h3>
      <div className="acessibilidade_titulo_internas">
        <a href="#">
          <i className="fa fa-print"></i>
        </a>
        <a href="#">
          <i className="fa fa-share-alt"></i>
        </a>
      </div>
    </div>
  );
}

export default ConteudoInternasTitulo;
