import separador_data from "../static/img/separador_data.png";

import { API_SERVER } from "../constants";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function IndexEventos() {
  const [eventos, setEventos] = useState([]);
  const [errorEventos, setErrorEventos] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/eventos`)
      .then((res) => res.json())
      .then(
        (data) => {
          setEventos(data);
        },
        (error) => {
          setErrorEventos(error);
        }
      );
  }, []);

  let eventos_message = <h3>{t("Mensagem de erro eventos")}</h3>;

  if (!errorEventos) {
    eventos_message = eventos.map((evento, index) => (
      <li key={index} className="conteudo_calendario_direito">
        <a href="evento.html">
          <div className="data_evento">
            <h5>{evento.begin.split("-")[2]}</h5>
            <img src={separador_data} />
            <h6>{evento.begin.split("-")[1]}</h6>
          </div>
          <span className="texto_evento">{evento.title_pt}</span>
        </a>
      </li>
    ));
  }

  return (
    <div className="eventos">
      <div
        className="titulo_index_eventos"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <div className="titulo_index_eventos"> */}
        <div
          style={{
            backgroundColor: "#cccd5f",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            marginLeft: "-30px",
          }}
        ></div>
        <h3 style={{ marginLeft: "auto" }}>{t("Eventos")}</h3>
        {/* <h3>{t("Eventos")}</h3> */}
      </div>
      <ul>{eventos_message}</ul>
      <div className="mais">
        <Link to="/eventos">
          {t("SAIBA")} <i className="fa fa-plus-circle"></i>
        </Link>
      </div>
    </div>
  );
}

export default IndexEventos;
