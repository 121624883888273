// import logo from "./logo.svg";
// import "./App.css";
// import banner_01 from "./static/img/banner_01.png";
import "./static/css/geral.css";
import "./static/css/internas.css";
import "./static/css/internas_html_page.css";
import "./static/css/pagination.css";
import "./static/css/outras.css";
import "./static/css/main.css";
import { API_SERVER } from "./constants";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import { useState, useEffect } from "react";
// import { Fragment } from "react";
import Home from "./components/Home";
import Index from "./components/Index";
// import QuemSomos from "./components/QuemSomos";
import Oportunidades from "./components/Oportunidades";
import GruposDePesquisa from "./components/GruposDePesquisa";
import PesquisaPublicacoes from "./components/PesquisaPublicacoes";
import Membros from "./components/Membros";
import MembrosDetalhes from "./components/MembrosDetalhes";
import GruposDePesquisaPaginaDoGrupo from "./components/GruposDePesquisaPaginaDoGrupo";
import EnsinoGraduacaoProfessores from "./components/EnsinoGraduacaoProfessores";
// import EnsinoApresentacao from "./components/EnsinoApresentacao";
// import EnsinoGraduacaoLinksGraduacao from "./components/EnsinoGraduacaoLinksGraduacao";
// import EnsinoGraduacaoContatoGraduacao from "./components/EnsinoGraduacaoContatoGraduacao";
// import EnsinoGraduacaoDuvidas from "./components/EnsinoGraduacaoDuvidas";
// import EnsinoGraduacaoInfraestrutura from "./components/EnsinoGraduacaoInfraestrutura";
import EnsinoGraduacaoDisciplinas from "./components/EnsinoGraduacaoDisciplinas";
import EnsinoGraduacaoDisciplinasPaginaDisciplina from "./components/EnsinoGraduacaoDisciplinasPaginaDisciplina";
// import ExtensaoApresentacao from "./components/ExtensaoApresentacao";
import ExtensaoProjetos from "./components/ExtensaoProjetos";
// import ExtensaoContatos from "./components/ExtensaoContatos";
import HTMLPage from "./components/HTMLPage";
import Eventos from "./components/Eventos";
import EventosEvento from "./components/EventosEvento";
import { useReducer } from "react";
import { reducer, globalStateReducer } from "./reducer";
import { portugueseState, englishState, globalState } from "./reducer";
import { LanguageContext, GlobalStateContext } from "./context";
import OportunidadesPaginaOportunidade from "./components/OportunidadesPaginaOportunidade";
// import { LanguageProvider } from "./context";

const LanguageProvider = ({ children, languageState }) => {
  const [state, dispatch] = useReducer(reducer, languageState);

  return (
    <LanguageContext.Provider value={[state, dispatch]}>
      {children}
    </LanguageContext.Provider>
  );
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, globalState);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};

function App() {
  const [menu, setMenu] = useState([]);
  const [errorMenu, setErrorMenu] = useState({});

  useEffect(() => {
    fetch(`${API_SERVER}/api/v1/menu`)
      .then((res) => res.json())
      .then(
        (data) => setMenu(data),
        (error) => {
          setErrorMenu(error);
        }
      );
  }, []);

  const static_routes = menu.filter((item) => item.is_static);

  const static_routes_pt = static_routes.map((item) => (
    <Route path={item.link_pt} element={<HTMLPage />} />
  ));
  const static_routes_en = static_routes.map((item) => (
    <Route path={item.link_en} element={<HTMLPage />} />
  ));

  const dynamic_routes_pt = [
    <Route path="instituto/membros" element={<Membros />}>
      <Route path=":membro_type" element={<Membros />} />
    </Route>,
    <Route
      path="instituto/membros/:membro_type/:membro_name"
      element={<MembrosDetalhes />}
    />,
    <Route path="instituto/oportunidades" element={<Oportunidades />} />,
    <Route
      path="instituto/oportunidades/:oportunidade_codigo"
      element={<OportunidadesPaginaOportunidade />}
    />,
    <Route path="pesquisa/publicacoes" element={<PesquisaPublicacoes />}>
      <Route path=":group_id" element={<PesquisaPublicacoes />}>
        <Route path=":year" element={<PesquisaPublicacoes />} />
      </Route>
    </Route>,
    <Route path="pesquisa/grupos" element={<GruposDePesquisa />} />,
    <Route
      path="pesquisa/grupos/:group_id"
      element={<GruposDePesquisaPaginaDoGrupo />}
    />,
    <Route
      path="graduacao/professores"
      element={<EnsinoGraduacaoProfessores />}
    />,
    <Route
      path="graduacao/Disciplinas"
      element={<EnsinoGraduacaoDisciplinas />}
    />,
    <Route
      path="graduacao/Disciplinas/:disciplina_codigo"
      element={<EnsinoGraduacaoDisciplinasPaginaDisciplina />}
    />,
    <Route
      path="graduacao/disciplinasOferecidas"
      element={<EnsinoGraduacaoDisciplinas />}
    />,
    <Route path="instituto/outrasatividades" element={<ExtensaoProjetos />} />,
    <Route path="eventos" element={<Eventos />} />,
    <Route path="eventos/:evento_id" element={<EventosEvento />} />,
  ];

  const dynamic_routes_en = [
    <Route path="institute/members" element={<Membros />}>
      <Route path=":membro_type" element={<Membros />} />
    </Route>,
    <Route
      path="institute/members/:membro_type/:membro_name"
      element={<MembrosDetalhes />}
    />,
    <Route path="institute/opportunities" element={<Oportunidades />} />,
    <Route
      path="institute/opportunities/:oportunidade_codigo"
      element={<OportunidadesPaginaOportunidade />}
    />,
    <Route path="research/publication" element={<PesquisaPublicacoes />}>
      <Route path=":group_id" element={<PesquisaPublicacoes />}>
        <Route path=":year" element={<PesquisaPublicacoes />} />
      </Route>
    </Route>,
    <Route path="research/groups" element={<GruposDePesquisa />} />,
    <Route
      path="research/groups/:group_id"
      element={<GruposDePesquisaPaginaDoGrupo />}
    />,
    <Route path="undergrad/faculty" element={<EnsinoGraduacaoProfessores />} />,
    // <Route path="undergrad/presentation" element={<EnsinoApresentacao />} />,
    <Route
      path="undergrad/disciplines"
      element={<EnsinoGraduacaoDisciplinas />}
    />,
    <Route
      path="undergrad/disciplines/:disciplina_codigo"
      element={<EnsinoGraduacaoDisciplinasPaginaDisciplina />}
    />,
    <Route
      path="undergrad/disciplinesOffered"
      element={<EnsinoGraduacaoDisciplinas />}
    />,
    <Route path="institute/otheractivities" element={<ExtensaoProjetos />} />,
    <Route path="events" element={<Eventos />} />,
    <Route path="events/:evento_id" element={<EventosEvento />} />,
  ];

  const browserLanguage = navigator.language || navigator.userLanguage;
  const languageState = browserLanguage.includes("pt")
    ? portugueseState
    : englishState;

  document.title =
    languageState === portugueseState
      ? "Instituto do Cérebro"
      : "Brain Institute";

  return (
    <GlobalStateProvider>
      <LanguageProvider languageState={languageState}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="" element={<Index />} />
              {dynamic_routes_pt}
              {static_routes_pt}
              {dynamic_routes_en}
              {static_routes_en}
            </Route>
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </GlobalStateProvider>
  );
}

export default App;
