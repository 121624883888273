import { IMAGE_SERVER_BASE_URL, API_SERVER } from "./constants";
// import { useContext } from "react";
// import { GlobalStateContext } from "../context";

function formatDate(date, is_pt) {
  const locale = is_pt ? "pt-BR" : "en-US";
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(date).toLocaleDateString(locale, options);
}

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

function handleThumbnail(image) {
  const image_server_base_url = `${API_SERVER}/static/core/_imgs`;
  const default_image_path = "img_pagina.jpg";

  if (!image) {
    return `${image_server_base_url}/${default_image_path}`;
  } else if (image.includes('/media/_imgs')){
    const image_path = image.split("_imgs/")[1];
    return `${IMAGE_SERVER_BASE_URL}/${image_path}`;
  } else if (image.includes('https://')) {
    return image;
  }
  else {
    return `${API_SERVER}${image}`;
  }
  
}

function deepcopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  
  // Create a new object or array
  const copy = Array.isArray(obj) ? [] : {};
  
  // Iterate over the keys of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Recursively deep copy each value
      copy[key] = deepcopy(obj[key]);
    }
  }
  
  return copy;
}

export { formatDate, handleThumbnail, deepcopy, titleCase };
