import { API_SERVER } from "../constants";
import { Link, useSearchParams } from "react-router-dom";
import { Markup } from "interweave";
import { range, slice } from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LanguageContext } from "../context";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";

const MONTH_MAPPING = {
  1: ["JAN", "JAN"],
  2: ["FEV", "FEB"],
  3: ["MAR", "MAR"],
  4: ["ABR", "APR"],
  5: ["MAI", "MAY"],
  6: ["JUN", "JUN"],
  7: ["JUL", "JUL"],
  8: ["AGO", "AUG"],
  9: ["SET", "SEP"],
  10: ["OUT", "OCT"],
  11: ["NOV", "NOV"],
  12: ["DEZ", "DEC"],
};

function build_link(year, month) {
  return `/eventos?selectYear=${year}&selectMonth=${month}`;
}

function handle_month(month) {
  return month == 0 ? 12 : month;
}

function build_date(date) {
  const date_elements = date.split("-");
  return new Date(
    Number(date_elements[0]),
    Number(date_elements[1]) - 1,
    Number(date_elements[2])
  );
}

function YearMonthCard({ year, month, selected_month, li_style, is_pt }) {
  const style_to_apply = month == selected_month ? li_style : {};
  const month_name = MONTH_MAPPING[month];

  return (
    <li style={style_to_apply}>
      <Link to={build_link(year, month)}>
        <div className="mes">{is_pt ? month_name[0] : month_name[1]}</div>
        <div className="ano">{year}</div>
      </Link>
    </li>
  );
}

function EventCard({ id, title, text, begin, end, t }) {
  const event_link = `/eventos/${id}`;
  const text_to_display = `${slice(text.split(" "), 0, 51).join(" ")}...`;

  return (
    <li>
      <h6 className="title_entry">{title}</h6>
      <b>Data de Início: </b>
      {begin.toLocaleDateString("pt-BR")}
      <br />
      <b>Data de Termino: </b>
      {end.toLocaleDateString("pt-BR")}
      <p>
        <Markup content={text_to_display} />
        <Link to={event_link}>
          {t("Veja")} <i className="fa fa-plus-circle"></i>
        </Link>
      </p>
      <br />
    </li>
  );
}

function EventListElement({ event_list, is_pt, t }) {
  return event_list.map((event, index) => (
    <EventCard
      index={index}
      id={event.id}
      title={is_pt ? event.title_pt : event.title_en}
      text={is_pt ? event.text_pt : event.text_en}
      begin={build_date(event.begin)}
      end={build_date(event.end)}
      t={t}
    />
  ));
}

function isPastEvent(event, selected_month, selected_year) {
  const date = build_date(event.begin);

  if (date.getFullYear() < selected_year) {
    return true;
  }

  return date.getMonth() + 1 < selected_month;
}

function Eventos() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { t, _ } = useTranslation();
  const [state, dispatch] = useContext(LanguageContext);
  const is_pt = state.language === "pt";

  const year_param = Number(searchParams.get("selectYear"));
  const month_param = Number(searchParams.get("selectMonth"));
  const today = new Date();

  const months_numbers = range(1, 13);
  const selected_year = year_param == 0 ? today.getFullYear() : year_param;
  const selected_month = month_param == 0 ? today.getMonth() + 1 : month_param;
  const next_month = handle_month((selected_month + 1) % 12);
  const prev_month = handle_month((selected_month - 1) % 12);
  const next_year =
    next_month < selected_month ? selected_year + 1 : selected_year;
  const prev_year =
    prev_month > selected_month ? selected_year - 1 : selected_year;

  const li_style = { background: "#cccb32" };

  const year_month_cards = months_numbers.map((month, index) => (
    <YearMonthCard
      index={index}
      year={selected_year}
      month={month}
      selected_month={selected_month}
      li_style={li_style}
      is_pt={is_pt}
    />
  ));

  const [eventList, setEventList] = useState([]);
  const [errorEventList, setErrorEventList] = useState({});

  const endpoint_events = `${API_SERVER}/api/v1/eventos?selectYear=${selected_year}&selectMonth=${selected_month}`;

  const fetch_events = () => {
    fetch(endpoint_events)
      .then((res) => res.json())
      .then(
        (data) => {
          setEventList(data);
        },
        (error) => {
          setErrorEventList(error);
        }
      );
  };

  useEffect(() => {
    fetch_events();
  }, [selected_year, selected_month]);

  const past_events = eventList.filter((event) =>
    isPastEvent(event, today.getMonth() + 1, today.getFullYear())
  );

  const active_events = eventList.filter(
    (event) => !isPastEvent(event, today.getMonth() + 1, today.getFullYear())
  );

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={t("EVENTOS")} />
        <div className="conteudo_internas documentos">
          <div id="eventos">
            <div id="eventos_ano">
              <Link
                to={build_link(prev_year, prev_month)}
                className="seta_eventos_esquerdo"
              >
                <i className="fa fa-chevron-left"></i>
              </Link>
              <ul>{year_month_cards}</ul>
              <Link
                to={build_link(next_year, next_month)}
                className="seta_eventos_direito"
              >
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
            <h5>{t("Eventos Abertos")}</h5>
            <ul>
              <EventListElement t={t} event_list={active_events} is_pt={is_pt} />
            </ul>

            <h5>{t("Eventos Encerrados")}</h5>
            <ul>
              <EventListElement t={t} event_list={past_events} is_pt={is_pt} />
            </ul>
          </div>
        </div>
      </div>
    </content>
  );
}

export default Eventos;
