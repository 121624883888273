import { Fragment } from "react";
import { Link } from "react-router-dom";

function NumberButton({ baseURL, number, offset, limit, is_current_step }) {
  const cssClass = is_current_step ? "currentStep step" : "step";
  const url = `${baseURL}?offset=${offset}&max=${limit}`;

  return offset !== -1 ? (
    <Link to={url} className={cssClass}>
      {number}
    </Link>
  ) : (
    <Fragment className={cssClass}>{number}</Fragment>
  );
}

function Paginacao({ baseURL, pageCount, offset, limit }) {
  const paginationButtons = [];
  const pageNumber = Math.round(offset / limit + 1);
  let iterNumber = pageNumber < 10 ? 1 : pageNumber;
  let iterNumberLimit = pageNumber < 10 ? iterNumber + 9 : iterNumber + 5;
  let iterOffset = pageNumber < 10 ? 0 : parseInt(offset);

  if (pageNumber >= 10) {
    paginationButtons.push({
      number: 1,
      offset: 0,
    });

    paginationButtons.push({
      number: " .. ",
      offset: -1,
    });

    for (
      let prevButtonNumber = pageNumber - 5;
      prevButtonNumber < pageNumber;
      prevButtonNumber += 1
    ) {
      paginationButtons.push({
        number: prevButtonNumber,
        offset: (prevButtonNumber-1) * limit,
      });
    }
  }

  for (
    ;
    iterOffset < pageCount && iterNumber <= iterNumberLimit;
    iterOffset += limit
  ) {
    paginationButtons.push({
      number: iterNumber,
      offset: iterOffset,
    });
    iterNumber += 1;
  }

  if (iterOffset < pageCount) {
    paginationButtons.push({
      number: " .. ",
      offset: -1,
    });
    paginationButtons.push({
      number: Math.round(pageCount / limit),
      offset: pageCount - limit,
    });
  }

  return (
    <div className="paginacao">
      {paginationButtons.map((pageButton, index) => (
        <NumberButton
          key={index}
          baseURL={baseURL}
          number={pageButton.number}
          offset={pageButton.offset}
          limit={limit}
          is_current_step={pageButton.number === pageNumber}
        />
      ))}
    </div>
  );
}

export default Paginacao;
