import { API_SERVER } from "../constants";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext, GlobalStateContext } from "../context";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Markup } from "interweave";
import { split, join } from "lodash";
import ConteudoInternasTitulo from "./ConteudoInternasTitulo";
import Paginacao from "./Paginacao";

function GruposDePesquisaGrupoCard({ grupo_info, is_pt, globalState, updateBossForGroup }) {
  const [boss, setBoss] = useState(null);
  const [errorBoss, setErrorBoss] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    updateBossForGroup(grupo_info.id, setBoss, setErrorBoss); // Chama a função para atualizar o boss
  }, [grupo_info.id, updateBossForGroup]);

  const boss_link = boss
    ? is_pt
      ? `/instituto/membros/chefes_de_laboratorio/${boss.short_name}`
      : `/institute/members/investigators/${boss.short_name}`
    : "#";

  const theses_link = `${boss_link}#thesis`;
  const members_link = is_pt
    ? `/pesquisa/grupos/${grupo_info.id}`
    : `/research/groups/${grupo_info.id}`;
  const members_pubs_link = is_pt
    ? `/pesquisa/publicacoes/${grupo_info.id}`
    : `/research/publication/${grupo_info.id}`;

  const image_path = grupo_info.image;
  const img_url = `${API_SERVER}/${image_path}`;

  let lab_summary = join(split(grupo_info.summary_chosen, " ", 150), " ");

  if (lab_summary !== grupo_info.summary_chosen) {
    lab_summary = `${lab_summary}...`;

    if (lab_summary.slice(-7) !== "</p>") {
      lab_summary = `${lab_summary}</p>`;
    }
  }

  return (
    <li className="geral">
      <h4>{grupo_info.name_chosen}</h4>
      <ul>
        <li className="menu_grupos">
          <aside className="menu_membros">
            <ul>
              <img src={img_url} className="decore_image" />
              <li>
                <Link to={boss_link}>{t("Chefes de laboratório")}</Link>
              </li>
              <li>
                <Link to={members_link}>{t("Membros")}</Link>
              </li>
              <li>
                <Link to={members_pubs_link}>{t("Publicações")}</Link>
              </li>
              <li>
                <Link to={theses_link}>{t("Teses")}</Link>
              </li>
            </ul>
          </aside>
        </li>
        <li>
          <Markup content={lab_summary} />
          <div className="mais">
            <Link to={members_link}>
              Leia <i className="fa fa-plus-circle"></i>
            </Link>
          </div>
        </li>
      </ul>
    </li>
  );
}


function getChosenProperties(group, state) {
  const is_pt = state.language == "pt";

  group.name_chosen = is_pt ? group.name_pt : group.name_en;
  group.summary_chosen = is_pt ? group.summary_pt : group.summary_en;

  return group;
}

function GruposDePesquisa() {
  const [grupos, setGrupos] = useState([]);
  const [numeroDeGrupos, setNumeroDeGrupos] = useState(0);
  const [errorGrupos, setErrorGrupos] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [state, _] = useContext(LanguageContext);
  const [globalState, __] = useContext(GlobalStateContext);

  const limit = 10;
  let offset = 0;
  if (searchParams.get("offset")) {
    offset = searchParams.get("offset");
  }

  const endpoint_grupos = `${API_SERVER}/api/v1/pesquisa/grupos?limit=${limit}&offset=${offset}`;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetch(endpoint_grupos)
      .then((res) => res.json())
      .then(
        (data) => {
          setGrupos(
            data.items.map((group) => getChosenProperties(group, state))
          );
          setNumeroDeGrupos(data.count);
        },
        (error) => {
          setErrorGrupos(error);
        }
      );
  }, [state, endpoint_grupos]);

  // Nova função para buscar e atualizar o boss
  const updateBossForGroup = (grupoId, setBoss, setErrorBoss) => {
    const endpoint_boss = `${API_SERVER}/api/v1/pesquisa/grupos/${grupoId}/boss`;
    fetch(endpoint_boss)
      .then((res) => res.json())
      .then(
        (data) => {
          setBoss(data);
        },
        (error) => {
          setErrorBoss(error);
        }
      );
  };

  const is_pt = state.language === "pt";
  const page_title = is_pt ? "Grupos de pesquisa" : "Research groups";

  return (
    <content>
      <div id="centralizar_interna">
        <ConteudoInternasTitulo page_title={page_title} />
        <div className="conteudo_internas documentos">
          <div className="grupos">
            <ul>
              {grupos.map((grupo, index) => (
                <GruposDePesquisaGrupoCard
                  grupo_info={grupo}
                  key={index}
                  is_pt={is_pt}
                  globalState={globalState}
                  updateBossForGroup={updateBossForGroup} // Passa a função de atualização
                />
              ))}
            </ul>
          </div>
        </div>
        <Paginacao
          baseURL={useLocation().pathname}
          pageCount={numeroDeGrupos}
          offset={offset}
          limit={limit}
        />
      </div>
    </content>
  );
}


export default GruposDePesquisa;
